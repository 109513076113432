import ImageOutputDisplay from '@/modules/studio/features/interaction/playground/result/ImageOutputDisplay';
import TextOutputDisplay from '@/modules/studio/features/interaction/playground/result/TextOutputDisplay';
import { trunc } from '@/modules/studio/utils/string';
import { Modalities } from '@llumiverse/core';
import { ErrorBox } from '@reactik/components';
import { ExecutionRun } from '@vertesia/common';

interface ResultPanelProps {
    run: ExecutionRun | null | undefined;
}
export default function ResultPanel({ run }: ResultPanelProps) {
    if (!run) {
        return null;
    } else {
        return (
            <div>
                <_ResultPanel run={run} />
                <div className="py-2 text-sm text-gray-700 dark:text-gray-300">by <span className="font-semibold">{trunc(run.modelId, 32)}</span> in {(run.execution_time || 0) / 1000}s</div>
            </div>
        );
    }
}

interface _ResultPanelProps {
    run: ExecutionRun;
}
function _ResultPanel({ run }: _ResultPanelProps) {
    if (run.error) {
        return (
            <div className="mt-2">
                <ErrorBox title="Failed to run interaction">
                    <pre className="whitespace-pre-wrap" style={{ wordBreak: 'break-word' }}>{run.error.message}</pre>
                    <pre className="whitespace-pre-wrap" style={{ wordBreak: 'break-word' }}>{run.error.data || ''}</pre>
                </ErrorBox>
            </div>
        );
    } else if (run.output_modality === Modalities.image) {
        return <ImageOutputDisplay result={run.result} interaction_name={run.interaction.name} />;
    } else if (run.result) {
        return <TextOutputDisplay run={run} />;
    }

    return null;
}