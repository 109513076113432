import clsx from 'clsx'

interface ResourceInfoProps {
    resource: any
    className?: string
}
export function ResourceInfo({ resource, className }: Readonly<ResourceInfoProps>) {
    const label = resource ? resource.name : "Unknown";
    if (resource?.email) {
        return <div className="flex flex-col">
            <span className={clsx("text-sm", className)}>{label}</span>
            <span className={clsx("text-xs", className)}>{resource.email}</span>
        </div>
    }

    return <span className={clsx("text-sm", className)}>{label}</span>
}