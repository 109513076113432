import { useUserSession } from "@/session/UserSession";
import { InteractionRef } from "@vertesia/common";
import { ErrorBox, Table, TBody } from "@reactik/components";
import { useFetch } from "@reactik/hooks";
import { FullHeightLayout } from "@reactik/layout";
import { useNavigate } from "@reactik/router";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

interface PublicInteractionsViewProps {
}
export default function PublicInteractionsView({ }: PublicInteractionsViewProps) {
    const { client } = useUserSession();
    const { data: interactions, error, isLoading } = useFetch(() => client.get('/public/interactions'), []);

    if (error) {
        return <ErrorBox title="Failed to fetch interactions">{error.message}</ErrorBox>;
    }

    return (
        <FullHeightLayout>
            <div className='px-4 py-8 mb-4 bg-gray-50 dark:bg-inherit border-b border-solid'>
                <h1 className='text-2xl font-bold'>Public Interactions</h1>
            </div>
            <FullHeightLayout.Body className="overflow-y-auto">
                <InteractionsTable interactions={interactions} isLoading={isLoading} />
            </FullHeightLayout.Body>
        </FullHeightLayout>
    )

}


interface InteractionsTableProps {
    interactions?: InteractionRef[];
    isLoading: boolean;
}

function InteractionsTable({ interactions, isLoading }: InteractionsTableProps) {
    return (
        <Table className="w-full h-full overflow-y-auto">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Organization</th>
                    <th>Updated</th>
                </tr>
            </thead>
            <TBody isLoading={isLoading} columns={4}>
                {interactions?.map((interaction) => <InteractionRow key={interaction.id} interaction={interaction} />)}
            </TBody>
        </Table>
    );
}

interface InteractionRowProps {
    interaction: InteractionRef;
}

function InteractionRow({ interaction }: InteractionRowProps) {
    const navigate = useNavigate();

    return (
        <tr className="hover:bg-muted/50 hover:cursor-pointer"
            onClick={() => navigate(`/shared/${interaction.id}`)}>
            <td className="flex flex-col">
                <span className="font-semibold">{interaction.name}</span>
                {/*<span className="text-slate-500">{interaction.description}</span>*/}
            </td>
            <td>{(interaction as any).project?.account?.name}</td>
            <td>{dayjs(interaction.updated_at).fromNow()}</td>
        </tr>
    );
}