import clsx from "clsx";
import "./blinking-cursor.css";

interface BlinkingCursorProps {
    className?: string;
}
export default function BlinkingCursor({ className }: BlinkingCursorProps) {
    // &#9646; vertical filled rectangle
    // &#124; vertical line
    return (
        <span className={clsx(className, "pl-1 text-lg text-gray-800 dark:text-gray-200", "blinking-cursor")}>|</span>
    )
}
