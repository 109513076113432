import { useEffect, useState } from 'react';

import SelectAIModel from '@/modules/studio/features/environment/SelectAIModel';
import SelectOrCreateEnvironment from '@/modules/studio/features/environment/SelectOrCreateEnvironment';
import { FormItem } from '@/components/FormItem';
import SelectRunDataLevel from '@/modules/studio/features/interaction/configuration/SelectRunDataLevel';
import PlaygroundState from '@/modules/studio/features/interaction/playground/PlaygroundState';
import { useUserSession } from '@/session/UserSession';
import { AIModel, ModelOptionsInfo, ModelOptions, OptionType, getOptions, ModelOptionInfoItem } from '@llumiverse/core';
import { Divider, InputList, NumberInput, SelectBox, Spinner } from '@reactik/components';
import { useCompositeState } from '@reactik/hooks';
import { ExecutionEnvironmentRef, InteractionExecutionConfiguration, RunDataStorageLevel } from '@vertesia/common';
import OptionInput from '@/modules/studio/features/interaction/OptionInput';

export default function RunConfigurationEditor() {
    const { client } = useUserSession();
    const [isLoaded, setIsLoaded] = useState(false);
    const playgroundState = useCompositeState(PlaygroundState);
    const interaction = playgroundState.interaction;
    const [env, setEnv] = useState<ExecutionEnvironmentRef | undefined>(playgroundState.env || undefined);
    //const [modelConfig, setModelConfig] = useState<ModelOptions | undefined>(playgroundState.config?.model_options || interaction.model_options);
    const [holdingModelConfig, setHoldingModelConfig] = useState<ModelOptions | undefined>(undefined);
    const [modelConfig, setModelConfig] = useState<ModelOptions | undefined>(
        {...interaction.model_options, ...playgroundState.config?.model_options } as ModelOptions
    );
    const [model, setModel] = useState<string | undefined>(playgroundState.config?.model || interaction.model);
    const [run_data, setRunData] = useState<RunDataStorageLevel | undefined>(playgroundState.config?.run_data || interaction.restriction);
    const [optionData, setOptionData] = useState<ModelOptionsInfo>();

    const onSelectEnv = (newEnv: ExecutionEnvironmentRef) => {
        if (newEnv.id === env?.id) {
            return;
        }
        setEnv(newEnv);
        playgroundState.env = env;
    };

    //Update option Id when optionData changes
   // useEffect(() => {
    //    setModelConfig({ ...modelConfig, _option_id: optionData?._option_id as any });
    //}, [optionData?._option_id]);

    //When optionData changes we need to clear no longer valid options
    //And move no longer valid options to holdingModelConfig
    //And new modelConfig set to the holdingModelConfig values or default.
    useEffect(() => {
        let newModelConfig: ModelOptions = { _option_id: optionData?._option_id as any };
        let newHoldingModelConfig: ModelOptions = holdingModelConfig ?? { _option_id: modelConfig?._option_id as any };
        if (modelConfig) {
            Object.entries(modelConfig).forEach(([key, value]) => {
                if (optionData?.options.find(option => option.name === key)) {
                    //If the option is still valid, propagate
                    newModelConfig[key as keyof ModelOptions] = value;
                } else {
                    //If the option is no longer valid, move to holding
                    newHoldingModelConfig[key as keyof ModelOptions] = value;
                }
            });
        }
        optionData?.options.forEach(option => {
            if (!newModelConfig[option.name as keyof ModelOptions]) {
                //If the option is new, use holding value or default
                newModelConfig[option.name as keyof ModelOptions] =
                    holdingModelConfig?.[option.name as keyof ModelOptions] ?? option.default as any;
            }
        });
        if (JSON.stringify(newModelConfig) !== JSON.stringify(modelConfig)) {
            setModelConfig(newModelConfig);
        }
        setHoldingModelConfig(newHoldingModelConfig);
    }, [optionData]);

    //When playgroundState changes we need to update the config
    useEffect(() => {
        //TODO: This refreshes optionData on every change, should be optimized to use refresh flag
        //And only trigger when certain options are changed
        const opts = getOptions(env?.provider, model, modelConfig);
        if (JSON.stringify(opts) !== JSON.stringify(optionData)) {
            setOptionData(opts);
        }

        playgroundState.config = {
            ...playgroundState.config,
            model: model,
            run_data: run_data,
            environment: env?.id,
            model_options: modelConfig,
        };
    }, [modelConfig, model, run_data, env]);

    useEffect(() => {
        if (!playgroundState.env && interaction.environment) {
            client.environments
                .retrieve(interaction.environment as string)
                .then((env) => {
                    setEnv(env);
                })
                .catch((err) => {
                    console.error("Failed to load execution environment", err);
                })
                .finally(() => setIsLoaded(true));
        } else {
            setIsLoaded(true);
        }
    }, [interaction.environment, client.environments]);

    const setAIModel = (model?: AIModel) => {
        setModel(model?.id);
    };

    const onChangeField = (name: string, value: any) => {
        setModelConfig({ ...modelConfig, [name as keyof ModelOptions]: value });
    };

    const onChangeRunData = (value: string) => {
        const run_data: RunDataStorageLevel = value as RunDataStorageLevel;
        setRunData(run_data);
        playgroundState?.config && (playgroundState.config.run_data = run_data);
    };

    return isLoaded ? (
        <div className="flex w-full flex-col gap-4 mt-4">
            <FormItem label="Select an execution environment">
                <SelectOrCreateEnvironment
                    value={env}
                    onChange={onSelectEnv}
                    selectFirst
                />
            </FormItem>
            <FormItem label="Select a Model">
                {env && (
                    <SelectAIModel
                        models={env?.enabled_models}
                        selected={model}
                        onSelect={setAIModel}
                        env={env}
                        allowSearch={true}
                        size="sm"
                    />
                )}
            </FormItem>
            <FormItem label="Run Data">
                <SelectRunDataLevel
                    value={run_data}
                    onChange={(value) => onChangeRunData(value)}
                />
            </FormItem>
            <div className="grid grid-cols-2 gap-4">
                <OptionInput options={optionData?.options ?? []} onChangeField={onChangeField} modelConfig={modelConfig} />
            </div>
        </div>
    ) : (
        <div className="flex justify-center">
            <Spinner size="lg" />
        </div>
    );
}