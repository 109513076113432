import Env from '@/env.ts';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { reactPlugin } from '@datadog/browser-rum-react';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';

if (Env.datadog) {
  datadogRum.init({
    applicationId: 'abb2e6b5-6696-464d-b659-76e9e355b56d',
    clientToken: 'pub479254b982154a0ef8aa6f7eba9aa968',
    site: 'us3.datadoghq.com',
    service: 'composable-ui',
    env: Env.type,
    version: Env.version,
    sessionSampleRate: Env.isProd ? 30 : 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    plugins: [reactPlugin()],
  });
  datadogLogs.init({
    clientToken: 'pub479254b982154a0ef8aa6f7eba9aa968',
    site: 'us3.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'composable-ui',
    env: Env.type,
    version: Env.version,
    // See "Scrub sensitive data from your Browser logs"
    // https://docs.datadoghq.com/logs/log_collection/javascript/#scrub-sensitive-data-from-your-browser-logs
    beforeSend: (log, _) => {
      log.view.url = log.view.url.replace(/token=[^&]*/, "token=REDACTED")
      if (log.view.url_details) {
        const params = log.view.url_details as any;
        if (params.hash) {
          params.hash = "REDACTED"
        }
      }
      return true;
    }
  });
} else {
  console.log('Datadog disabled');
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
