import Popover from "@/components/popover/Popover";
import { ModelOptionInfoItem, ModelOptions, OptionType } from "@llumiverse/core";
import { Divider, InputList, NumberInput, SelectBox } from "@reactik/components";
import clsx from "clsx";
import { ReactNode } from "react";
import { FormItem } from "@/components/FormItem";

interface OptionInputProps {
    options: ModelOptionInfoItem[];
    onChangeField: (name: string, value: any) => void;
    modelConfig?: ModelOptions;
}
export default function OptionInput({ options, onChangeField, modelConfig }: OptionInputProps) {
    return options.map((option) => (
        <OptionInputItem key={option.name} option={option} onChangeField={onChangeField} modelConfig={modelConfig} />
    ));
}

interface OptionInputItemProps {
    option: ModelOptionInfoItem;
    onChangeField: (name: string, value: any) => void;
    modelConfig?: ModelOptions;
}
function OptionInputItem({ option, onChangeField, modelConfig }: OptionInputItemProps) {
    let input_form = <p>Option Type not recognised</p>;
    if (option.type === OptionType.numeric) {
        input_form = <NumberInput
            name={option.name}
            value={modelConfig?.[option.name as keyof ModelOptions] as unknown as number}
            onChange={(value) => onChangeField(option.name, value)}
            min={option.min}
            max={option.max}
            step={option.step ?? (option.integer ? 1 : 0.1)}
            noScroll
            placeholder="Model default"
        />;
    } else if (option.type === OptionType.enum) {
        input_form = <SelectBox
            options={Object.entries(option.enum).map(([id, name]) => (name))}
            value={modelConfig?.[option.name as keyof ModelOptions]}
            onChange={(value) => onChangeField(option.name, value)}
            placeholder="Select an option"
        />;
    } else if (option.type === OptionType.string_list) {
        input_form = <InputList
            value={modelConfig?.[option.name as keyof ModelOptions] as unknown as string[]}
            onChange={(value) => onChangeField(option.name, value)}
        />
    } else if (option.type === OptionType.boolean) {
        input_form = <input type="checkbox"
            onChange={(value) => onChangeField(option.name, value.target.checked)}
            checked={(modelConfig?.[option.name as keyof ModelOptions] as unknown as boolean) == true}
        />;
    }

    return (
        <FormItem label={SentenceCaseName(option.name)} description={DescriptionWithLimits(option)}>
            {input_form}
        </FormItem>
    );
}

function SentenceCaseName(name: string) {
    return name
        .replace(/([A-Z])/g, ' $1') // Split on capital letters
        .replace(/[-_]/g, ' ') // Replace hyphens and underscores with spaces
        .toLowerCase() // Convert to lowercase
        .trim() // Remove leading/trailing spaces
        .split(' ') // Split into words
        .filter(word => word) // Remove empty strings
        .map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word) // Capitalize the first word
        .join(' ');
}

function DescriptionWithLimits(option: ModelOptionInfoItem): string {
    const limits: string[] = [];
    // Construct min,max, and default string
    if (option.type === OptionType.numeric) {
        // Explicit undefined check because of falsy values (0, false, etc.)
        if (option.min !== undefined) {
            limits.push(`Min: ${option.min}`);
        }
        if (option.max !== undefined) {
            limits.push(`Max: ${option.max}`);
        }
        if (option.default !== undefined) {
            limits.push(`Default: ${option.default}`);
        }
    }

    const limitsString = limits.join(', ');

    if (option.description) {
        return `${option.description} ${limitsString ? `(${limitsString})` : ''}`;
    } else {
        return limitsString;
    }
}