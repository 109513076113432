import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useUserSession } from '@/session/UserSession';
import { Input } from '@reactik/components';
import { TooltipPopover } from '@/components/TooltipPopover';
import { Info } from 'lucide-react';

const NAMESPACE_REGEX = /^[a-zA-Z0-9-_\[\]]+$/;

interface ProjectNamespaceWidgetProps {
    namespace?: string;
    onChange: (value: string) => void;
    setIsValid: (value: boolean) => void;
}
export default function ProjectNamespaceWidget({ namespace, onChange, setIsValid }: ProjectNamespaceWidgetProps) {

    const { client } = useUserSession();
    const [originalNamespace] = useState<string | undefined>(namespace);
    const [currentNamespace, setCurrentNamespace] = useState<string | undefined>(namespace);
    const [isNsAvailable, setIsNsAvailable] = useState<boolean | undefined>(undefined);
    const [isNsValid, setIsNsValid] = useState<boolean>(true);
    const [debouncedNs] = useDebounce(currentNamespace, 500);

    const isNamespaceChanged = originalNamespace !== namespace;

    useEffect(() => {
        setCurrentNamespace(namespace);
    }, [namespace]);

    useEffect(() => {
        if (!currentNamespace) {
            setIsNsAvailable(false);
            setIsValid(false);
            setIsNsValid(false);
            return;
        }

        if (currentNamespace === originalNamespace) {
            setIsNsAvailable(undefined);
            const isValid = NAMESPACE_REGEX.test(currentNamespace);
            setIsNsValid(isValid);
            setIsValid(isValid);
            return;
        }

        if (currentNamespace.length < 3) {
            setIsNsAvailable(false);
            setIsNsValid(true);
            setIsValid(false);
            return;
        }

        client.commands.isNamespaceAvailable(currentNamespace).then((isAvailable) => {
            setIsNsAvailable(isAvailable);
            const isValid = NAMESPACE_REGEX.test(currentNamespace);
            setIsNsValid(isValid);
            setIsValid(isValid && isAvailable);
            onChange(currentNamespace)
        })
    }, [debouncedNs]);

    return (
        <div>
            <label className="block text-sm py-2">Namespace
                <TooltipPopover
                    size='xs'
                    placement='right'
                    description='Namespace must be a globally unique name and can only contain alphanumeric characters and [-_]'>
                    <Info className="size-4 text-gray-400" />
                </TooltipPopover>
            </label>
            <Input className="w-full" value={currentNamespace} onChange={setCurrentNamespace} />
            
            <p className="text-xs pt-1"> </p>
            {currentNamespace && !isNsValid && <p className="text-xs text-red-500">Namespace <code>{currentNamespace}</code> is not valid</p>}
            {isNsValid && isNamespaceChanged && isNsAvailable === false && <p className="text-xs text-red-500">Namespace <code>{currentNamespace}</code> is not available</p>}
            {isNsValid && isNamespaceChanged && isNsAvailable === true && <p className="text-xs text-green-500">Namespace <code>{currentNamespace}</code> is available</p>}
        </div>
    )

}
