import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";

import { cn } from "../libs/utils";
import { Tab, TabsContext as _TabContext } from '../tabs/TabsContext.js';

const TabsContext = React.createContext<{ size?: number }>({ size: undefined });

interface TabsProps {
  current?: string | (() => string);
  /**
   * Return false to prevent the tab from being selected
   * @param tab
   * @returns
   */
  tabs: Tab[];
  defaultValue?: string;
  className?: string;
  fullWidth?: boolean;
  // onSelect?: (tab: Tab) => void | boolean;
  // children?: React.ReactNode | React.ReactNode[];
  // navigate?: (path: string) => void;
}

const _Tabs = ({ tabs, defaultValue, className, fullWidth }: TabsProps) => (
  <TabsPrimitive.Root defaultValue={defaultValue} className={className}>
    <TabsList size={fullWidth ? tabs.length : 0} className={fullWidth ?  "w-full" : ""}>
      {tabs.map((tab) => (
        <TabsTrigger key={tab.name} value={tab.name}>
          {tab.label}
        </TabsTrigger>
      ))}
    </TabsList>
    {tabs.map((tab) => (
      <TabsContent key={tab.name} value={tab.name} className="w-full px-0">
        {tab.content}
      </TabsContent>
    ))}
  </TabsPrimitive.Root>
);

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & { size?: number }
>(({ className, size, ...props }, ref) => (
  <TabsContext.Provider value={{ size }}>
    <TabsPrimitive.List
      ref={ref}
      className={cn(
        "inline-flex h-9 items-center justify-center rounded-lg bg-muted p-1 text-muted-foreground",
        className
      )}
      {...props}
    />
  </TabsContext.Provider>
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const { size } = React.useContext(TabsContext);
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        "hover:cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1 text-sm font-medium ring-offset-background transition-all focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-xs",
        className,
        size ? `w-1/${size}` : ""
      )}
      {...props}
    />
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "mt-2 ring-offset-background focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { _Tabs  , TabsList, TabsTrigger, TabsContent };