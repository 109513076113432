import { useUserSession } from "@/session/UserSession";
import { AccountRef } from "@vertesia/common";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalTitle, Portal, useToast } from "@reactik/components";
import clsx from "clsx";
import { useState } from "react";
import { isVertesiaEmail } from "@/utils/email";
// import CreateProjectModal from "./CreateProjectModal";
import { Switcher } from "@reactik/components";
import { Building } from "lucide-react";
import { SidebarItem, useSidebarToggle } from "@reactik/layout";

interface AccountSelectionNavProps {
    className?: string
}

export default function AccountSelectionNav({ className }: AccountSelectionNavProps) {
    const session = useUserSession();
    const { account, accounts, user, isLoading } = session;

    const doSwitchAccount = (acc: AccountRef) => {
        const accountId = acc.id;
        console.log("doSwitchAccount: ", accountId, accounts, session);
        session.switchAccount(accountId);
    }

    if (!session || !user) return null;

    // const hasMultiAccounts = user.accounts.length > 1;

    const isVertesiaAccount = isVertesiaEmail(user.email);


    return (
        <AccountSelector
            className={clsx('w-auto', className)}
            selected={account ?? undefined}
            onChange={doSwitchAccount}
            accounts={accounts ?? []}
            placeholder={isLoading ? 'Loading Projects...' : 'Select Project'}
            allowCreate={isVertesiaAccount ? true : false}
        />
    );
}

interface AccountSelectorProps {
    className?: string
    onChange: (project: AccountRef) => void
    selected?: AccountRef
    accounts: AccountRef[]
    allowCreate?: boolean
    placeholder?: string
}

export function AccountSelector({ className, onChange, accounts, selected, placeholder, allowCreate }: AccountSelectorProps) {

    // const { accounts } = useUserSession();
    const { isOpen, toggleDesktop } = useSidebarToggle();
    const [showModal, setShowModal] = useState(false);


    const getLabel = (project: AccountRef) => {
        return project.name;
    };

    return (
        !isOpen ?
            <SidebarItem current={false} onClick={() => toggleDesktop()}
                href="#" icon={Building}>Organization</SidebarItem> :
            (<>
                <Switcher
                    by="name"
                    value={selected}
                    options={accounts}
                    disabled={accounts.length === 1 && !allowCreate}
                    optionLabel={(option) => getLabel(option)}
                    addNew={allowCreate ? () => setShowModal(true) : undefined}
                    addNewLabel={allowCreate ? 'Add Organization' : undefined}
                    placeholder={placeholder ?? 'Select Organization'}
                    filterBy="name"
                    onChange={onChange}
                    label="Organization" />
                <Portal>
                    <CreateAccountModal isOpen={showModal} onClose={() => setShowModal(false)} />
                </Portal>
            </>
            )
    );
}


interface CreateAccountModalProps {
    isOpen?: boolean;
    onClose: () => void;
}
function CreateAccountModal({ isOpen = false, onClose }: CreateAccountModalProps) {
    const toast = useToast();
    const { client, switchAccount } = useUserSession();
    const [isCreating, setIsCreating] = useState(false);
    const [name, setName] = useState<string | undefined>(undefined);

    const doCreate = () => {
        if (!name) {
            toast({
                status: 'error',
                title: 'Invalid name',
                description: 'Name is required',
                duration: 5000
            });
            return;
        }

        return client.accounts.create(name).then(r => {
            switchAccount(r.id);
        }).catch((err) => {
            toast({
                status: 'error',
                title: 'Error creating organization',
                description: err.message,
                duration: 9000
            });
        }).finally(() => {
            setIsCreating(false);
        })
    }

    return (
        <>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalTitle>Create an Organization</ModalTitle>
                <ModalBody>
                    <div className="mb-2">
                        Enter a name for the organization
                    </div>
                    <div>
                        <Input className="w-full" label="Enter a name for the organization" value={name} onChange={(value: string) => setName(value)}/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant='secondary' onClick={onClose}>Cancel</Button>
                    <Button isLoading={isCreating} onClick={doCreate}>Create</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
