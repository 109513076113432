import { Property, createCompositeStateProvider } from "@reactik/hooks";
import { ManagedObject } from "@reactik/json";
import { VertesiaClient } from "@vertesia/client";
import { ConfigModes, ExecutionEnvironmentRef, ExecutionRun, Interaction, InteractionExecutionConfiguration, PopulatedInteraction, mergePromptsSchema } from "@vertesia/common";
import { JSONSchema4 } from "json-schema";

export enum PlaygroundExecutionStatus {
    initial,
    pending,
    streaming,
    done,
    error
}

export default class PlaygroundState {

    payload: ManagedObject;
    config: InteractionExecutionConfiguration | undefined;
    env: ExecutionEnvironmentRef | undefined; // the selected environment
    result_schema?: JSONSchema4 | undefined;

    executionRun: ExecutionRun | undefined;
    error: Error | undefined;

    executionStatus = new Property<PlaygroundExecutionStatus>(PlaygroundExecutionStatus.initial);
    // executionResult = new Property<ExecutionRun>();
    // executionError = new Property<Error>();
    streamingText = new Property<string>();

    constructor(public client: VertesiaClient, public interaction: Interaction) {
        const schema: JSONSchema4 = mergePromptsSchema(interaction as PopulatedInteraction) || {};
        this.payload = new ManagedObject(schema, interaction.test_data || {});
    }

    get status() {
        return this.executionStatus.value;
    }

    run() {
        const config = this.config;
        if (!config) {
            return Promise.reject(new ConfigValidationError("Run configuration not defined"));
        }
        if (!this.config?.model && !this.env?.provider.startsWith('virtual_')) {
            return Promise.reject(new ConfigValidationError("Model was not defined"));
        }
        config.configMode = ConfigModes.RUN_CONFIG_ONLY;       //Playground should always use the run config only
        this.error = undefined;
        this.executionRun = undefined;
        this.executionStatus.value = PlaygroundExecutionStatus.pending;
        return this.client.runs.create({
            interaction: this.interaction.id,
            data: this.payload.value,
            result_schema: this.result_schema || {}, // Use the schema that the user has overridden
            config,
            tags: ['studio'],
            stream: true
        }).then(result => {
            //this.executionResult.value = result;
            this.executionRun = result;
            this.executionStatus.value = PlaygroundExecutionStatus.streaming;
            return result;
        }).catch((err: any) => {
            if (!(err instanceof Error)) {
                err = new Error(err.message ? err.message : String(err));
            }
            //this.executionError.value = err;
            this.error = err;
            this.executionStatus.value = PlaygroundExecutionStatus.error;
            throw err;
        });
    }

    static Provider = createCompositeStateProvider(PlaygroundState);
}


export class ConfigValidationError extends Error {
    constructor(message: string) {
        super(message);
    }
}