import { ProjectRef, ProjectRoles, User } from "@vertesia/common"
import { SharedState, useWatchSharedState } from "@reactik/hooks"
import { createContext, useContext } from "react"

export class RoleSelection {
    selected?: 'user' | 'context' | 'role' = 'context'
    user?: User
    project?: ProjectRef
    role?: ProjectRoles

    select(type: 'user' | 'context' | 'role' | undefined) {
        const selection = this.clone()
        selection.selected = type
        return selection
    }

    clone() {
        const selection = new RoleSelection()
        selection.selected = this.selected
        selection.user = this.user
        selection.project = this.project
        selection.role = this.role
        return selection
    }

    renderValue(type: string) {
        switch (type) {
            case 'user':
                return this.user ? (this.user.name ? `${this.user.name} (${this.user.email})` : this.user.email) : undefined
            case 'context':
                return this.project?.name
            case 'role':
                return this.role
        }
    }

    setValue(type: string, value: any) {
        switch (type) {
            case 'user':
                this.user = value
                break
            case 'context':
                this.project = value
                break
            case 'role':
                this.role = value
                break
        }
        return this.clone()
    }
}

export const RoleSelectionContext = createContext<SharedState<RoleSelection>>(undefined as any)

export function useRoleSelection() {
    const context = useContext(RoleSelectionContext)
    if (!context) {
        throw new Error('useRoleSelection must be used within a RoleSelectionProvider')
    }
    return context
}

export function useWatchRoleSelection() {
    return useWatchSharedState(useRoleSelection())
}