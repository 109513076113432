import { useState, useEffect } from 'react'
import ProjectNamespaceWidget from '@/modules/studio/features/project/ProjectNamespaceWidget'
import { useUserSession } from '@/session/UserSession'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalTitle, Styles, useToast } from '@reactik/components'
import { Checkbox, Textarea } from '@headlessui/react'
import { Check, Info } from 'lucide-react'
import { TooltipPopover } from '@/components/TooltipPopover'
import clsx from 'clsx'

interface CreateProjectModalProps {
    isOpen?: boolean
    onClose: () => void
}
export default function CreateProjectModal({ isOpen = false, onClose }: Readonly<CreateProjectModalProps>) {
    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <ModalTitle>Create a Project</ModalTitle>
            <CreateProjectForm onClose={onClose} />
        </Modal>
    )
}

interface CreateProjectFormProps {
    onClose: () => void
}
function CreateProjectForm({ onClose }: Readonly<CreateProjectFormProps>) {
    const session = useUserSession()
    const { client } = session

    const toast = useToast()
    const [projectName, setProjectName] = useState<string | undefined>(undefined)
    const [description, setDescription] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [autoConfig, setAutoConfig] = useState(true)
    const [namespace, setNamespace] = useState('')
    const [isNsValid, setIsNsValid] = useState(false)

    useEffect(() => {
        setNamespace(textToId(projectName ?? ''))
    }, [projectName])

    const onSubmit = () => {
        if (!isNsValid) {
            toast({
                status: 'error',
                title: 'Invalid namespace',
                description: 'Namespace must be at least 3 characters long and contain only alphanumeric characters, and [-_] ',
                duration: 5000
            })
            return
        }

        const name = projectName?.trim() ?? namespace

        setIsLoading(true)
        client.projects.create({
            name,
            namespace,
            description,
            auto_config: autoConfig
        }).then((project) => {
            session.switchProject(project.id)
        }).catch((err) => {
            toast({
                status: 'error',
                title: 'Failed to create project',
                description: err.message,
                duration: 5000
            })
        }).finally(() => {
            setIsLoading(false)
            onClose()
        })
    }

    return (
        <>
            <ModalBody className='text-sm'>
                <div className='mb-2'>Project Name</div>
                <Input className="w-full" value={projectName} onChange={setProjectName} />
                <ProjectNamespaceWidget namespace={namespace} onChange={setNamespace} setIsValid={setIsNsValid} />
                <div className='my-2 flex align-center'>
                    <div className='shrink-0 mr-1'>Project Description</div>
                    <TooltipPopover
                        size='xs'
                        placement='right'
                        description='Describe about the project to pass additional context to the default environment and model used for generation and achieve better results with Vertesia workflow'>
                        <Info className="size-4 text-gray-400" />
                    </TooltipPopover>
                </div>
                <Textarea className={clsx('w-full dark:bg-slate-800 text-sm resize-none rounded-md', Styles.INPUT)}
                    value={description} onChange={(e) => setDescription(e.target.value)} rows={3} />
                <div className='flex items-center justify-between px-1'>
                    <div className='my-2 flex align-center'>
                        <div className='shrink-0 mr-1'>Auto-configure the Project</div>
                        <TooltipPopover
                            size='xs'
                            placement='right'
                            description='Initialize the project with shared environments on Amazon Bedrock and Google Vertex AI that are free to use, and sample prompts and interactions that get you started immediately'>
                            <Info className="size-4 text-gray-400" />
                        </TooltipPopover>
                    </div>
                    <Checkbox
                        checked={autoConfig}
                        onChange={setAutoConfig}
                        className="group size-5 rounded-sm border bg-white data-checked:bg-blue-500 flex justify-center items-center"
                    >
                        <Check className="size-3 text-white" />

                    </Checkbox>
                </div>
            </ModalBody>
            <ModalFooter justify="end">
                <Button isDisabled={!isNsValid} isLoading={isLoading} onClick={onSubmit}>Create</Button>
            </ModalFooter>
        </>
    )
}

function textToId(value: string) {
    return value.toLowerCase().replace(/[^a-z0-9-_]/g, '-').replace(/-+/g, '-')
}
