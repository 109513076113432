import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

import { ChevronRight, Info } from 'lucide-react';
import { HamburgerButton } from '@reactik/layout';
import { TooltipPopover } from './TooltipPopover';

interface GenericPageNavHeaderProps {
    title: string | ReactElement;
    description?: string | ReactElement;
    actions?: ReactNode | ReactNode[];
    breadcrumbs?: ReactElement[]
    isCompact?: boolean
    children?: ReactNode
    className?: string
}

export default function GenericPageNavHeader({ className, children, title, description, actions, breadcrumbs, isCompact = false }: GenericPageNavHeaderProps) {
    return (

        <div className={clsx(isCompact ? 'pb-0' : 'pb-4', 'border-b border-solid p-4 flex flex-col', className)}>
            <div className='flex items-start gap-4'>
                <div className="w-full flex place-content-between h-auto min-h-8">
                    <div className="flex gap-4 h-full items-center">
                        {!isCompact && <HamburgerButton />}
                        <div>
                            <nav className="flex-1 flex justify-start text-xs">
                                {breadcrumbs?.map((breadcrumb, index) => {
                                    return (
                                        <div className="flex items-center dark:brightness-75" key={index}>
                                            {breadcrumb}
                                            {index < breadcrumbs.length - 1 && <ChevronRight className="h-6" />}
                                        </div>
                                    )
                                })}
                            </nav>
                            <div className='flex gap-2'>
                                <h1 className="text-2xl font-semibold w-full">{title}</h1>
                                {
                                    description &&
                                    <TooltipPopover
                                        description={description}>
                                        <Info className="size-4 text-gray-400" />
                                    </TooltipPopover>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-x-4">{actions}</div>
            </div>
            {children && <div className="w-full flex items-center py-4">{children}</div>}
        </div>
    )
}