import { useUserSession } from "@/session/UserSession";
import { Button } from "@reactik/components";
import { BillingMethod, StripeBillingStatusResponse } from "@vertesia/common";
import { useEffect, useState } from "react";




export default function AccountBillingView() {

    const { client, account } = useUserSession();
    const [billingStatus, setBillingStatus] = useState<StripeBillingStatusResponse|undefined>(undefined);

    useEffect(() => {
        client.account.getStripeBillingStatus().then((status) => {
            setBillingStatus(status);
        });
    }, [account]);


    if (!billingStatus) {
        return <div>Loading...</div>
    }

    return (
        <div className="flex flex-col gap-4 max-w-screen-sm">
            <h1 className="text-2xl font-bold">Billing</h1>
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-semibold">Billing Status</h2>
                <p>{billingStatus.status}</p>
            </div>
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-semibold">Billing Method</h2>
                <p>{billingStatus.billing_method}</p>
            </div>

            {billingStatus.status === "enabled" && billingStatus.billing_method === BillingMethod.stripe && (
                <div className="flex flex-col gap-2 max-w-44">
                    <h2 className="text-xl font-semibold">Stripe Billing</h2>
                   <Button size="sm" onClick={() => {
                        window.open(billingStatus.portal_url, "_blank");
                    }}>Go to Billing Portal</Button>
                </div>
            )}
        
        </div>
    )

}