import clsx from "clsx"
import { useState } from "react"

import { SelectBox } from "@reactik/components"
import { ProjectRoles } from '@vertesia/common'

const NO_ROLE = 'No Role'

interface RoleSelectorProps {
    className?: string
    onChange: (role: string | undefined) => void
}
export default function RoleSelector({ className, onChange }: Readonly<RoleSelectorProps>) {
    const [role, setRole] = useState<string | undefined>(undefined)
    const roles = [NO_ROLE, ...Object.values(ProjectRoles)]

    const onRoleChange = (role: string) => {
        const value = role === NO_ROLE ? undefined : role

        setRole(value)
        onChange(value)
    }

    return (
        <SelectBox
            className={clsx('w-auto min-w-[165px]', className)}
            value={role}
            options={roles}
            placeholder={'Filter by Role'}
            onChange={onRoleChange}
        />
    )
}