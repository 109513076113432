import { RadioGroup } from '@headlessui/react'
import clsx from 'clsx'


interface SelectStackOption {
    id: string
    label: string
    description?: React.ReactNode
}

interface SelectStackProps {
    onSelect: (option: SelectStackOption) => void
    selected?: SelectStackOption
    options: SelectStackOption[]
}

export function SelectStack({ onSelect, selected, options }: SelectStackProps) {

    return (
        <RadioGroup value={selected} onChange={onSelect}>
            <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
            <div className="space-y-4">
                {options.map((option) => (
                    <RadioGroup.Option
                        key={option.id}
                        value={option}
                        className={({ active }) =>
                            clsx(
                                active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-slate-300 dark:border-slate-600',
                                'relative block cursor-pointer rounded-lg border bg-white dark:bg-slate-800 px-6 py-4 shadow-2xs focus:outline-hidden sm:flex sm:justify-between'
                            )
                        }
                    >
                        {({ active, checked }) => (
                            <>
                                <span className="flex items-center">
                                    <span className="flex flex-col text-sm">
                                        <RadioGroup.Label as="span" className="font-medium text-slate-900 dark:text-slate-200">
                                            {option.label}
                                        </RadioGroup.Label>
                                        {option.description &&
                                            <RadioGroup.Description as="span" className="text-slate-500 dark:text-slate-400">
                                                {option.description}
                                            </RadioGroup.Description>
                                        }
                                    </span>
                                </span>
                                <span
                                    className={clsx(
                                        active ? 'border' : 'border-2',
                                        checked ? 'border-indigo-600' : 'border-transparent',
                                        'pointer-events-none absolute -inset-px rounded-lg'
                                    )}
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
}
