import { useAccessControlEntryRegistry } from '@/features/settings/iam/AccessControlEntryRegistry'
import { ChangeOrganizationComponent, SetProjectRoleComponent } from '@/features/settings/iam/RoleComponent'
import { useUserPermissions } from '@/session/permissions/UserPermissionsProvider'
import { CircleHelp } from 'lucide-react'
import { Button, IndigoBadge, Modal, ModalBody, ModalTitle, Table } from '@reactik/components'
import { AccountRef } from '@vertesia/common'
import { useFlag } from '@reactik/hooks'

interface PermissionsTitleProps {
    account?: AccountRef
    updateRole?: boolean
    title: string
}
export function PermissionsTitle({ account, updateRole = false, title }: Readonly<PermissionsTitleProps>) {
    const registry = useAccessControlEntryRegistry()
    return (
        <div className="flex items-center w-full" >
            <div>{title}</div>
            <RolesMappingModal variant="ghost">
                <CircleHelp className="size-5" />
            </RolesMappingModal>
            {
                updateRole && (
                    <div className="ml-auto">
                        <AddRoleModal account={account} refresh={() => registry.refresh()} />
                    </div>
                )
            }
        </div>
    )
}

interface AddRoleModalProps {
    account?: AccountRef
    refresh: () => unknown
}
function AddRoleModal({ account, refresh }: Readonly<AddRoleModalProps>) {
    const { on, off, isOn } = useFlag(false)
    const onDone = () => {
        off()
        refresh()
    }

    return (
        <>
            <Button onClick={on}>{account ? "Change Role" : "Set Role"}</Button>
            <Modal onClose={off} isOpen={isOn} className="max-w-[50vw]!">
                <ModalTitle>{account ? "Change User Role" : "Set User Role"}</ModalTitle>
                <ModalBody>
                    {
                        account
                            ? <ChangeOrganizationComponent account={account} onDone={onDone} />
                            : <SetProjectRoleComponent onDone={onDone} />
                    }
                </ModalBody>
            </Modal>
        </>
    )
}

interface RolesMappingModalProps {
    children?: React.ReactNode
    variant?: "primary" | "secondary" | "soft" | "ghost" | "unstyled"
}
export function RolesMappingModal({ variant, children }: Readonly<RolesMappingModalProps>) {
    const { on, off, isOn } = useFlag(false)
    const perms = useUserPermissions()

    return (
        <>
            <Button variant={variant} onClick={on}>{children}</Button>
            <Modal onClose={off} isOpen={isOn} className="max-w-[50vw]!">
                <ModalTitle>Roles to Permissions Mapping</ModalTitle>
                <ModalBody className="overflow-y-scroll max-h-[60vh] ">
                    <Table className='w-full'>
                        <thead>
                            <tr>
                                <td>Role</td>
                                <td>Permissions</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                perms.system_roles.map((role) => {
                                    return (
                                        <tr key={role.name}>
                                            <td>{role.name}</td>
                                            <td>
                                                <div className="flex gap-2 flex-wrap">
                                                    {
                                                        role.permissions.map((perm) => {
                                                            return <IndigoBadge key={perm}>{perm}</IndigoBadge>
                                                        })
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
        </>
    )
}