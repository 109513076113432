import { AppLayout } from '@reactik/layout';
import { AppMenu } from './AppMenu';
import { NavBar } from './NavBar';
import PanelErrorBoundary from './errors/PanelErrorBoundary';
// import UserLogin from './features/login/UserLogin';
import { useTenantStickyParams } from './session/useTenantStickyParams';


// export function AppLogo() {
//     return <div className='text-lg text-white'>Composable</div>
// }

interface AppPageProps {
    title?: string;
    children: React.ReactNode | React.ReactNode[]
    className?: string;
}
export default function AppPage({ children, title, className }: AppPageProps) {
    useTenantStickyParams();
    const sidebarBg = 'bg-sidebar text-sidebar-foreground border-r border-sidebar-border w-full';
    return (
        <AppLayout title={title}
            className={className}
            sidebar={<AppMenu />}
            sidebarClassName={sidebarBg}
            mainNav={<NavBar />}
        >
            <PanelErrorBoundary>
                <PageContent>{children}</PageContent>
            </PanelErrorBoundary>
        </AppLayout>
    )
}

interface PageContentProps {
    children: React.ReactNode | React.ReactNode[]
}
function PageContent({ children }: PageContentProps) {
    //TODO you can use this to show a loading spinner or to switch pages on authentication flow.
    return <>{children}</>
}
