import { useState } from 'react'

import AcesView from '@/features/settings/iam/AcesView'
import { InviteUserToOrgWidget } from '@/features/settings/iam/InviteUserToOrg'
import { ViewPendingInvitations } from '@/features/settings/iam/ViewPendingInvitations'
import SettingsPanel from '@/features/settings/SettingsPanel'
import { PermissionsTitle } from '@/modules/studio/features/project/RolesMappingModal'
import { useUserSession } from '@/session/UserSession'

import AccessControlEntryProvider from './AccessControlEntryProvider'

export function OrgRolesView() {
    const { account } = useUserSession()
    const [trigger, setTrigger] = useState(false)

    const handleTrigger = () => {
        setTrigger((prev) => !prev)
    }

    return (
        <AccessControlEntryProvider>
            <div className="w-full flex flex-col gap-4">
                <SettingsPanel title={<PermissionsTitle title="Invite Users" />}>
                    <InviteUserToOrgWidget onInvitationSend={handleTrigger} />
                </SettingsPanel>

                <SettingsPanel title="Pending Invitations">
                    <ViewPendingInvitations refresh={trigger} />
                </SettingsPanel>

                <SettingsPanel title={<PermissionsTitle title="User & Role in the Organization" account={account} updateRole />}>
                    <AcesView type='organization' />
                </SettingsPanel>
            </div>
        </AccessControlEntryProvider>
    )
}