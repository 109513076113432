import { useEffect, useMemo, useState } from 'react';

import AppPage from '@/AppPage';
import { OrgRolesView } from '@/features/settings/iam/OrgRolesView';
import { ProjectRolesView } from '@/features/settings/iam/ProjectRolesView';
import IntegrationsSettings from '@/features/settings/IntegrationsSettings';
import ProjectConfigurationView from '@/modules/studio/features/project/ProjectConfigurationView';
import { useUserPermissions } from '@/session/permissions/UserPermissionsProvider';
import { Tabs, TabsBar, TabsPanel } from '@reactik/components';
import { useCompositeState, useSlot } from '@reactik/hooks';
import { FullHeightLayout } from '@reactik/layout';
import { useLocation } from '@reactik/router';
import { Account, Permission } from '@vertesia/common';

import AccountBillingView from '@/features/settings/AccountBillingView';
import GenericPageNavHeader from '../../components/GenericPageNavHeader';
import PanelErrorBoundary from '../../errors/PanelErrorBoundary';
import { useUserSession } from '../../session/UserSession';
import AccountSettings from './AccountSettings';
import { APIKeysView } from './APIKeysView';
import HeaderActionsState from './HeaderActionsState';

export default function SettingsView() {
    const { client, user } = useUserSession()
    const [account, setAccount] = useState<Account | null>(null)
    const actionsContext = useMemo(() => new HeaderActionsState(), []);

    useEffect(() => {
        user && client.account.info().then((account) => {
            setAccount(account)
        })
    }, [user]);

    const breadcrumbs = [breadcrumb(account?.name)];
    const title = "Settings";
    const description = ""

    return (
        <AppPage title="Settings">
            <HeaderActionsState.Provider value={actionsContext}>
                <FullHeightLayout>
                    <GenericPageNavHeader title={title} description={description} actions={<SettingsActions />} breadcrumbs={breadcrumbs} />
                    {account && <SettingsTabs account={account} />}
                </FullHeightLayout>
            </HeaderActionsState.Provider>
        </AppPage >
    )
}

function breadcrumb(value?: string) {
    if (value) {
        return <span>{value}</span>
    }

    return <></>
}

function SettingsActions() {
    const actionsContext = useCompositeState(HeaderActionsState);
    const slot = useSlot(actionsContext.action);
    return slot;
}

interface SettingsTabsProps {
    account: Account;
}
function SettingsTabs({ account }: Readonly<SettingsTabsProps>) {
    const { hash } = useLocation();
    const currentTab = hash ? hash.substring(1) : "account";
    const perms = useUserPermissions();

    const isAccountManager = perms.hasPermission(Permission.account_manage);
    const isProjectManager = perms.hasPermission(Permission.project_manage);
    if (!isAccountManager && !isProjectManager) {
        return null;
    }

    const tabs = isAccountManager ? [
        {
            name: "account",
            label: "Organization",
            href: "#account",
            content: <AccountSettings account={account} />
        },
        {
            name: "project",
            label: "Project",
            href: "#project",
            content: <ProjectConfigurationView />
        },
        {
            name: "users",
            label: "Users",
            href: "#users",
            content: <OrgRolesView />
        },
        {
            name: "project_roles",
            label: "Project Roles",
            href: "#project_roles",
            content: <ProjectRolesView />
        },
        {
            name: "keys",
            label: "API Keys",
            href: "#keys",
            content: <APIKeysView />
        },
        {
            name: "integrations",
            label: "Integrations",
            href: "#integrations",
            content: <IntegrationsSettings />
        },
        {
            name: "billing",
            label: "Billing",
            href: "#billing",
            content: <AccountBillingView />
        }
    ] : [
        {
            name: "project",
            label: "Project",
            href: "#project",
            content: <ProjectConfigurationView />
        }
    ];

    return (
        <Tabs tabs={tabs} current={currentTab}>
            <TabsBar />
            <FullHeightLayout.Body>
                <PanelErrorBoundary>
                    <div className="h-[calc(100vh-168px)] overflow-auto">
                        <TabsPanel />
                    </div>
                </PanelErrorBoundary>
            </FullHeightLayout.Body>
        </Tabs>
    )
}
