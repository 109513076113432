import { useUserSession } from "@/session/UserSession";
import { ProjectRef } from "@vertesia/common";
import { Portal } from "@reactik/components";
import { useFetch } from "@reactik/hooks";
import clsx from "clsx";
import { useState } from "react";
import CreateProjectModal from "./CreateProjectModal";
import { Switcher } from "@reactik/components";
import { Folder } from "lucide-react";
import { SidebarItem, useSidebarToggle } from "@reactik/layout";


interface SelectProjectNavProps {
    className?: string
}

export default function SelectProjectNav({ className }: SelectProjectNavProps) {
    const { client, project, accounts, user, account, authToken } = useUserSession();
    const onProjectChange = (project: ProjectRef) => {
        if (project.id === "new") return; //if we selected create, ignore
        if (!account) return;
        window.location.replace(`/?a=${account?.id}&p=${project.id}`);
    };

    const { data: projects, isLoading } = useFetch(() => {
        if ((!user || !accounts) || isLoading || !authToken) return Promise.resolve([]);
        return client.projects.list(account?.id ? [account?.id] : undefined);
    }, [accounts, user, authToken]);

    return (
        <ProjectSelector
            className={clsx('w-auto', className)}
            selected={project ?? undefined}
            onChange={onProjectChange}
            projects={projects ?? []}
            placeholder={isLoading ? 'Loading Projects...' : 'Select Project'}
            allowCreate
            collasible
            label="Projects"
        />
    );
}

interface ProjectSelectorProps {
    className?: string
    onChange: (project: ProjectRef) => void
    selected?: ProjectRef
    projects: ProjectRef[]
    allowCreate?: boolean
    placeholder?: string
    collasible?: boolean
    label?: string
}

export function ProjectSelector({ className, onChange, projects, selected, placeholder, allowCreate, collasible = false, label = undefined}: ProjectSelectorProps) {
    const { isOpen, toggleDesktop } = useSidebarToggle();

    const { accounts } = useUserSession();
    const [showModal, setShowModal] = useState(false);

    //check if we have multiple orgs in the list of projects
    const hasMultipleOrgs = projects.some((p, i) => i > 0 && p.account !== projects[i - 1].account);

    const getProjectLabel = (project: ProjectRef) => {
        if (!hasMultipleOrgs) return project.name;

        const account = accounts?.find(a => a.id === project.account);
        return `[${account?.name}] ${project.name}`;
    };

    return (
        (!isOpen && collasible) ?
            <SidebarItem current={false} onClick={() => toggleDesktop()}
                href="#" icon={Folder}>Projects</SidebarItem> :
            (<>
                <Switcher
                    by="name"
                    className={className}
                    value={selected}
                    options={projects}
                    optionLabel={(option) => getProjectLabel(option)}
                    addNew={allowCreate ? () => setShowModal(true) : undefined}
                    addNewLabel={allowCreate ? 'Create New Project' : undefined}
                    placeholder={placeholder ?? 'Select Project'}
                    filterBy="name"
                    onChange={onChange}
                    disabled={projects.length === 1 && !allowCreate}
                    label={label} />
                <Portal>
                    <CreateProjectModal isOpen={showModal} onClose={() => setShowModal(false)} />
                </Portal>
            </>
            )
    );
}