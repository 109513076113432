import { ErrorBoundary } from '@datadog/browser-rum-react';

import { ErrorBoundaryProps } from './types';

export default function PanelErrorBoundary({ children }: ErrorBoundaryProps) {
    return (
        <ErrorBoundary fallback={PanelErrorFallback}>
            {children}
        </ErrorBoundary>
    );
}

export function PanelErrorFallback({ error }: { error?: Error }) {
    return (
        <div className="prose">
            <h2>Sorry, something went wrong...</h2>
            <p>Our team has been notified and will be quickly working on resolving it.
                If you'd like to escalate or simply contact us, please email us at&nbsp;
                <a href="mailto:support@vertesiahq.com">support@vertesiahq.com</a>.
            </p>
            {error?.message &&
                <pre>
                    {error.message}
                </pre>
            }
        </div>
    );
}
