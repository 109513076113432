import Env from "@/env";
import { datadogLogs } from "@datadog/browser-logs";
import { getAnalytics } from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { UIResolvedTenant } from "@/../api/types";

const firebaseConfig: FirebaseOptions = {
    apiKey: Env.firebase.apiKey,
    authDomain: Env.firebase.authDomain,
    projectId: "dengenlabs",
    appId: "1:265888598630:web:6e5e76c8ecde887e5afba7",
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);

export async function setFirebaseTenant() {
    if (!Env.firebase.tenantName) {
        console.log("No tenant name specified, skipping tenant setup");
        return;
    }

    try {
        console.log(`Resolving tenant ID for ${Env.firebase.tenantName}`);

        // Add retry logic with exponential backoff
        let retries = 3;
        let retryDelay = 250; // Start with 250ms delay

        while (retries > 0) {
            try {
                // Call the API endpoint to resolve the tenant ID
                const response = await fetch("/api/resolve-tenant", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ tenantName: Env.firebase.tenantName }),
                    // Add timeout to prevent hanging requests
                    signal: AbortSignal.timeout(5000),
                });

                // Check for network errors
                if (!response) {
                    throw new Error("No response received from tenant API");
                }

                // Handle HTTP error responses
                if (!response.ok) {
                    // Try to parse the error response
                    try {
                        const errorData = await response.json();
                        console.error("Failed to resolve tenant ID:", errorData.error);
                    } catch (parseError) {
                        console.error(`Failed to resolve tenant ID: HTTP ${response.status}`);
                    }

                    // If the error is 404 Not Found, no need to retry
                    if (response.status === 404) {
                        console.warn(`Tenant ${Env.firebase.tenantName} not found`);
                        return;
                    }

                    throw new Error(`HTTP error ${response.status}`);
                }

                // Successfully got a response, parse it
                const data = (await response.json()) as UIResolvedTenant;

                if (data && data.firebaseTenantId) {
                    firebaseAuth.tenantId = data.firebaseTenantId;
                    console.log(`Tenant ID set to ${firebaseAuth.tenantId}`);
                    return data;
                } else {
                    console.error(`Invalid response format, missing tenantId for ${Env.firebase.tenantName}`);
                    return; // No need to retry for invalid response format
                }
            } catch (fetchError) {
                // Only retry for network-related errors
                if (retries > 1) {
                    console.warn(`Tenant resolution failed, retrying in ${retryDelay}ms...`, fetchError);
                    await new Promise((resolve) => setTimeout(resolve, retryDelay));
                    retryDelay *= 2; // Exponential backoff
                    retries--;
                } else {
                    throw fetchError; // Last retry failed, propagate error
                }
            }
        }
    } catch (error) {
        // Final error handler
        console.error("Error setting Firebase tenant:", error instanceof Error ? error.message : "Unknown error");

        // Continue without tenant ID - authentication will work without multi-tenancy
        // but the user will access the default tenant
    }
}

async function getFirebaseAuthToken(refresh?: boolean) {
    const user = firebaseAuth.currentUser;
    if (user) {
        return user
            .getIdToken(refresh)
            .then((token) => {
                datadogLogs.logger.info("Got Firebase token", {
                    vertesia: {
                        user_email: user.email,
                        user_name: user.displayName,
                        user_id: user.uid,
                        refresh: refresh,
                    },
                });
                return token;
            })
            .catch((err) => {
                datadogLogs.logger.error("Failed to get Firebase token", {
                    vertesia: {
                        user_email: user.email,
                        user_name: user.displayName,
                        user_id: user.uid,
                        refresh: refresh,
                        error: err,
                    },
                });
                console.error("Failed to get access token", err);
                return null;
            });
    } else {
        datadogLogs.logger.warn("No user found");
        return Promise.resolve(null);
    }
}

function signOut() {
    return firebaseAuth.signOut();
}

export { analytics, firebaseApp, firebaseAuth, firebaseConfig, getFirebaseAuthToken, signOut };
