import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import { Check, ChevronsUpDown, Search, SquarePlus } from 'lucide-react';
import { useState, useEffect, useRef } from 'react';

import { Popover, PopoverContent, PopoverTrigger } from './popover';

interface SwithcherProps<T> {
    options: T[] | undefined;
    value?: T;
    optionLabel?: (option: T) => string;
    onChange: (option: T) => void;
    onBlur?: () => void;
    onKeyDown?: (e: React.KeyboardEvent, isOpen: boolean) => void;
    label?: string;
    placeholder?: string;
    addNew?: () => void;
    addNewLabel?: string;
    disabled?: boolean;
    filterBy?: string | ((o: T) => string);
    by?: (keyof T & string) | ((a: T, z: T) => boolean)
    className?: string;
    popupClass?: string;
}
export function Switcher({ options, optionLabel, value, onChange, addNew, addNewLabel, disabled, filterBy, label, placeholder, className, popupClass }: Readonly<SwithcherProps<any>>) {
    const triggerRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const [width, setWidth] = useState<number>(0);
    const [filterValue, setFilterValue] = useState('');

    useEffect(() => {
        const element = triggerRef.current;
        if (!element) {
            return;
        }

        const updateWidth = () => {
            const contentWidth = element.getBoundingClientRect().width;
            setWidth(contentWidth);
        };

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        updateWidth();
        resizeObserver.observe(element);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const filteredOptions = options?.filter((opt) => {
        const searchValue = filterValue.toLowerCase();
        if (!searchValue) {
            return true;
        }

        if (typeof filterBy === 'string' && filterBy in opt) {
            const columnValue = String(opt[filterBy]).toLowerCase();
            return columnValue.includes(searchValue);
        }

        const label = optionLabel ? optionLabel(opt) : String(opt);
        return label.includes(searchValue);
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValue(e.target.value);
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
    };

    const handleTriggerClick = (e: React.MouseEvent) => {
        if (disabled) {
            e.preventDefault();
            return;
        }
        setOpen(!open);
    };

    const _onClick = (opt: any) => {
        onChange(opt);
        setOpen(false);
    };

    return (
        <Popover open={!disabled && open} modal={false} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <div
                    ref={triggerRef}
                    onClick={handleTriggerClick}
                    className={clsx(
                        className,
                        'flex flex-row gap-2 items-center justify-between p-2 rounded-md group',
                        !disabled && "cursor-pointer hover:bg-accent hover:text-accent-foreground"
                    )}
                >
                    <div
                        className={clsx(
                            "flex flex-col w-fill rounded-md text-sm items-center justify-center truncate ",
                            !disabled && ""
                        )}
                    >
                        {label && <div className='w-full text-left text-xs font-semibold'>{label}</div>}
                        <div className={clsx('w-full text-left', !disabled && '')}>
                            {value ? (optionLabel ? optionLabel(value) : value) : placeholder}
                        </div>
                    </div>
                    {
                        !disabled && (
                            <ChevronsUpDown className="size-4 opacity-50" />
                        )
                    }
                </div>
            </PopoverTrigger>
            <PopoverContent
                style={{ width: `${width}px` }}
                className={clsx(
                    "min-w-[8rem] w-64 bg-popover p-1 border shadow",
                    "divide-y divide-border",
                    popupClass
                )}
            >
                {
                    filteredOptions &&
                    <div className='flex items-center px-2 gap-x-1'>
                        <Search size={16} />
                        <input
                            className={clsx(
                                'block m-0 p-0 w-full border-0 bg-transparent focus:border-transparent focus:ring-0',
                                'pl-1 py-2 w-full flex-1 text-sm border-none dark:text-white'
                            )}
                            placeholder={`Search ${label ?? ''}...`}
                            value={filterValue}
                            onChange={handleInputChange}
                            onKeyDown={handleInputKeyDown}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                }
                <div className='flex flex-col overflow-y-auto max-h-40'>
                    {
                        filteredOptions?.map((opt, i) => (
                            <div
                                key={i}
                                onClick={() => _onClick(opt)}
                                className='flex flex-row items-center justify-between p-2 gap-2 cursor-pointer hover:bg-accent rounded-md'
                            >
                                <div className='truncate text-sm'>{optionLabel ? optionLabel(opt) : opt}</div>
                                {isEqual(value, opt) && <Check className='size-4' />}
                            </div>
                        ))
                    }
                </div>
                {
                    addNew && (
                        <div className='p-1'>
                            <a
                                onClick={addNew}
                                className={clsx(
                                    'gap-x-2 px-2 py-1.5 truncate group flex rounded-md items-center text-sm cursor-pointer hover:bg-accent',
                                )}
                            >
                                <SquarePlus size={16} strokeWidth={1.25} absoluteStrokeWidth />
                                {addNewLabel}
                            </a>
                        </div>
                    )
                }
            </PopoverContent>
        </Popover>
    );
}