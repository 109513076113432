import clsx from 'clsx';
import { ReactNode } from 'react';

import { TooltipPopover } from '@/components/TooltipPopover';
import { Info } from 'lucide-react';

interface FormItemProps {
    label: any;
    children: ReactNode;
    className?: string;
    description?: ReactNode;
    required?: boolean;
}
export function FormItem({ description, required, label, className, children }: FormItemProps) {
    return (
        <div className={clsx("flex w-full flex-col space-y-1", className)}>
            <div className='flex items-center gap-1'>
                <label className="text-sm font-medium">
                    {label}{required ? <span className='text-red-600 -mt-4 ml-1'>*</span> : ""}
                </label>
                {
                    description &&
                    <div className='flex w-4 items-center'>
                        <TooltipPopover
                            description={description}>
                            <Info className="size-4" />
                        </TooltipPopover>
                    </div>
                }
            </div>
            {children}
        </div>
    );
}