import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { Trash2 } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'

import { ResourceInfo } from '@/features/settings/iam/components/ResourceInfo'
import ConfirmActionButton from '@/modules/store/features/store/objects/ConfirmActionButton'
import { AccessControlEntry, ResourceRef } from '@vertesia/common'
import { Input, Table, TBody } from '@reactik/components'
import RoleSelector from './components/RoleSelector'

dayjs.extend(LocalizedFormat)

interface FilterQuery {
    resourceQuery: string | undefined
    roleQuery: string | undefined
    userQuery: string | undefined
}

interface Ace {
    ace: AccessControlEntry
    principal: Principal
    resource: ResourceRef
    principalText: string
    resourceText: string
    type: string
}

interface Principal {
    id: string
    name: string
    email?: string
    picture?: string
}

interface AcesTableProps {
    aces: AccessControlEntry[] | undefined
    isLoading?: boolean
    refPool: ResourceRef[] | undefined
    type: "organization" | "project"
    onDelete: (ace: AccessControlEntry) => void
}
export function AcesTable({ aces, isLoading, refPool, type, onDelete }: Readonly<AcesTableProps>) {
    const [{ resourceQuery, roleQuery, userQuery }, setFilter] = useState<FilterQuery>({ resourceQuery: '', roleQuery: undefined, userQuery: '' })
    const onFilterChange = (resource: string, role: string | undefined, user: string) => {
        setFilter(
            {
                resourceQuery: resource.toLowerCase(),
                roleQuery: role,
                userQuery: user.toLowerCase()
            }
        )
    }

    const entries: Ace[] = useMemo(() => {
        if (refPool && aces) {
            return aces.reduce((result: Ace[], ace: AccessControlEntry) => {
                const principal = refPool.find(r => r.id === ace.principal) || { name: "Unknown", id: ace.principal } as Principal
                const resource = refPool.find(r => r.id === ace.resource) || { name: "Unknown", id: ace.resource } as ResourceRef
                let aceType: string

                if (!resource) {
                    aceType = "Unknown"
                } else if ((resource as any).account) {
                    aceType = "project"
                } else {
                    aceType = "organization"
                }

                if (type === aceType) {
                    result.push({
                        ace,
                        principal,
                        resource,
                        principalText: principal.name.toLowerCase(),
                        resourceText: resource.name.toLowerCase(),
                        type: aceType
                    })
                }

                return result
            }, [] as Ace[])
        } else {
            return []
        }
    }, [aces, refPool])

    const filteredEntries = resourceQuery || roleQuery || userQuery
        ? entries.filter(entry => {
            if (resourceQuery && !entry.resourceText.includes(resourceQuery)) {
                return false
            }

            if (roleQuery && entry.ace.role !== roleQuery) {
                return false
            }

            if (userQuery) {
                const principal = entry.principal
                const hasName = principal.name.toLowerCase().includes(userQuery)
                const hasEmail = principal.email?.toLowerCase().includes(userQuery)
                const hasId = principal.id.toLowerCase().includes(userQuery)
                if (!(hasName || hasEmail || hasId)) {
                    return false
                }
            }

            return true
        }) : entries

    return (
        <div className='border-t border-t-gray-200 pt-4'>
            <FilterBox type={type} onChange={onFilterChange} />
            <Table className="w-full">
                <thead>
                    <tr>
                        <td>User</td>
                        <td>Role</td>
                        {
                            type === 'project' && (
                                <td>Resource</td>
                            )
                        }
                        <td>Created At</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <TBody columns={type === 'project' ? 5 : 4} isLoading={isLoading} >
                    {
                        filteredEntries.map((entry) => {
                            return (
                                <tr key={entry.ace.id}>
                                    <td className="flex flex-col">
                                        <ResourceInfo resource={entry.principal} />
                                    </td>
                                    <td>{entry.ace.role}</td>
                                    {
                                        type === 'project' && (
                                            <td><ResourceInfo resource={entry.resource} /></td>
                                        )
                                    }
                                    <td>{dayjs(entry.ace.created_at).format('LL')}</td>
                                    <td>
                                        <ConfirmActionButton
                                            variant="ghost"
                                            doAction={onDelete}
                                            params={entry.ace}
                                            confirmationText={`Are you sure you want to delete this role?`}
                                        >
                                            <Trash2 className="size-5 text-red-500" />
                                        </ConfirmActionButton>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </TBody>
            </Table>
        </div>
    )
}

interface FilterBoxProps {
    type: "organization" | "project"
    onChange: (resource: string, role: string | undefined, user: string) => void
}
function FilterBox({ type, onChange }: Readonly<FilterBoxProps>) {
    const [resource, setResource] = useState('')
    const [role, setRole] = useState<string | undefined>(undefined)
    const [user, setUser] = useState('')

    useEffect(() => {
        onChange(resource, role, user)
    }, [resource, role, user])

    return (
        <div className='flex gap-2'>
            <Input placeholder="Filter by User Name, Email or ID" value={user} onChange={setUser} />
            <RoleSelector onChange={setRole} />
            {
                type === 'project' && (
                    <Input placeholder="Filter by Resource" value={resource} onChange={setResource} />
                )
            }
        </div>
    )
}