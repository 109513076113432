import { basicSetup } from 'codemirror';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash-es';

import { ExternalSchemaToolbar } from '@/modules/studio/components/ExternalSchemaToolbar';
import { getSchemaUri, resolveSchema, typeRefToSchemaSource } from '@/modules/studio/components/schema-source';
import PlaygroundState from '@/modules/studio/features/interaction/playground/PlaygroundState';
import { contentToJSON, jsonToContent } from '@/modules/studio/utils/test-data';
import { useUserSession } from '@/session/UserSession';
import { ContentObjectTypeRef, SchemaRef } from '@vertesia/common';
import { json } from '@codemirror/lang-json';
import { CodeMirrorEditor, EditorApi } from '@reactik/codemirror';
import { Button, Switch, ToastFn, useToast } from '@reactik/components';
import { useCompositeState, useFlag } from '@reactik/hooks';
import { ManagedSchema, SchemaEditor } from '@reactik/schema-editor';

const codemirrorExtensions = [basicSetup, json()];

export function SchemaOverrideEditor() {
    const { client } = useUserSession();
    const toast = useToast();
    const playgroundState = useCompositeState(PlaygroundState);
    const interactionSchema = structuredClone(playgroundState.interaction.interaction_schema);
    const editorRef = useRef<EditorApi | undefined>(undefined);
    const [status, setStatus] = useState<"default" | "overridden">(playgroundState.result_schema ? "overridden" : "default");
    const { toggle: toggleRawJson, isOn: isEditJsonOn } = useFlag();
    const [showSelectType, setShowSelectType] = useState(false);

    const updateSchema = (schema: ManagedSchema) => {
        const clone = schema.clone();
        setManagedSchema(clone);
    };

    const [managedSchema, setManagedSchema] = useState<ManagedSchema | undefined>(
        new ManagedSchema(playgroundState.result_schema || interactionSchema)
            .withChangeListener(updateSchema)
    );

    const applyModifications = () => {
        isEditJsonOn && saveJsonEditor(managedSchema, editorRef, toast);
        playgroundState.result_schema = managedSchema ? managedSchema.schema : undefined;
        if (playgroundState.result_schema && isEqual(interactionSchema, playgroundState.result_schema)) {
            setStatus("overridden");
        } else {
            setStatus("default");
        }
    };

    const removeOverride = () => {
        playgroundState.result_schema = structuredClone(interactionSchema);
        setManagedSchema(
            new ManagedSchema(playgroundState.result_schema)
                .withChangeListener(updateSchema)
        );
    };

    const jsonValue = useMemo(() => {
        return jsonToContent(managedSchema?.schema);
    }, [managedSchema]);

    useEffect(() => {
        const result_schema = playgroundState.result_schema;
        if (result_schema?.$uri) {
            resolveSchema(client, result_schema.$uri).then((result) => {
                setManagedSchema(
                    new ManagedSchema(result.schema)
                        .withSource(result.name, result.uri)
                        .withChangeListener(updateSchema)
                );
            });
        } else {
            setManagedSchema(
                new ManagedSchema(result_schema)
                    .withChangeListener(updateSchema)
            );
        }
    }, [client, playgroundState]);

    useEffect(() => {
        applyModifications();
    }, [managedSchema]);

    const onTypeSelectionChange = (typeRef: ContentObjectTypeRef | undefined) => {
        const ref = typeRef ? { $uri: getSchemaUri(typeRef), name: typeRef.name } : undefined;
        playgroundState.result_schema = ref;
        if (managedSchema) {
            resolveSchema(client, playgroundState.result_schema?.$uri).then((result) => {
                setManagedSchema(
                    new ManagedSchema(result.schema)
                        .withSource(result.name, result.uri)
                        .withChangeListener(updateSchema)
                );
            });
        }
    };

    const toggleSchemaEditor = () => {
        isEditJsonOn && saveJsonEditor(managedSchema, editorRef, toast);
        toggleRawJson();
    };

    let externalSchemaToolbar: React.ReactNode = null;
    const isTypeLinked = !!managedSchema?.source?.uri;
    if (isTypeLinked || showSelectType) {
        externalSchemaToolbar = (
            <div className='flex py-2 items-center gap-2 border-b border-b-gray-50'>
                <div className='flex-1'>
                    <ExternalSchemaToolbar source={managedSchema?.source} onTypeSelectionChange={onTypeSelectionChange} />
                </div>
                {status === "overridden" ?
                   ( <div>
                        <Button variant="primary" onClick={removeOverride} >Reset</Button> 
                    </div>)
                    : ''
                }
            </div>
        );
    } else {
        externalSchemaToolbar = (
            <div className='flex py-2 items-center gap-2 border-b border-b-gray-50'>
                <div className='flex-1'>
                    <Switch value={false} onChange={(checked) => setShowSelectType(checked)}>Use a Content Type</Switch>
                </div>
                {status === "overridden" ?
                    (<div>
                        <Button variant="primary" onClick={removeOverride} >Reset</Button> 
                    </div>)
                    : ''
                }
            </div>
        );
    }

    return (
        <div>
            {
                managedSchema && (
                    <div>
                        {externalSchemaToolbar}
                        <div className="flex gap-x-2 pt-2">
                            <Button variant={isEditJsonOn ? "ghost" : "soft"} onClick={toggleSchemaEditor}>Schema Editor</Button>
                            <Button variant={!isEditJsonOn ? "ghost" : "soft"} onClick={toggleSchemaEditor}>JSON Editor</Button>
                        </div>
                        {
                            isEditJsonOn ?
                                <CodeMirrorEditor value={jsonValue} extensions={codemirrorExtensions} editorRef={editorRef} />
                                :
                                <SchemaEditor schema={managedSchema} />
                        }
                    </div>
                )
            }
        </div>
    );
}

function saveJsonEditor(managedSchema: ManagedSchema | undefined, editorRef: React.MutableRefObject<EditorApi | undefined>, toast: ToastFn) {
    const value = editorRef.current?.getValue().trim();
    if (value) {
        try {
            managedSchema?.replaceSchema(contentToJSON(value));
        } catch (err: any) {
            toast({
                status: 'error',
                title: 'Invalid JSON Schema',
                description: err.message,
                duration: 5000
            });
        }
    } else {
        managedSchema?.replaceSchema(null);
    }
}
