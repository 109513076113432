
import { useRouterContext } from "@reactik/router";
import { useEffect } from "react";
import { useUserSession } from "./UserSession.ts";

export function useTenantStickyParams() {
    const session = useUserSession();
    const routerCtx = useRouterContext();
    useEffect(() => {
        if (!routerCtx.router) return;
        const router = routerCtx.router.getTopRouter();
        if (session.project && session.account) {
            router.setStickyParams({
                'p': session.project.id,
                'a': session.account.id,
            });
        }
    }, [routerCtx.router, session])
}