import { ThemeProvider, ToastProvider } from "@reactik/components";
import { RouterProvider } from "@reactik/router";
import { PageNotFound } from "./PageNotFound.tsx";
import SplashScreen from "./components/SplashScreen.tsx";
import SigninScreen from "./features/login/SigninScreen.tsx";
import TerminalLogin from "./features/login/TerminalLogin.tsx";
import SettingsView from "./features/settings/AccountSettingsView.tsx";
import PublicInteractionPage from "./modules/studio/public/PublicInteractionPage.tsx";
import PublicInteractionsPage from "./modules/studio/public/PublicInteractionsPage.tsx";
import { UserSessionProvider } from "./session/UserSessionProvider.tsx";
import { UserPermissionProvider } from "./session/permissions/UserPermissionsProvider.tsx";

const routes = [
    {
        path: '/settings',
        Component: SettingsView
    },
    {
        path: '/cli',
        Component: TerminalLogin
    },
    {
        path: '/studio/*',
        LazyComponent: () => import('./modules/StudioModule.tsx')
    },
    {
        path: '/store/*',
        LazyComponent: () => import('./modules/StoreModule.tsx')
    },
    // === public interfactions
    {
        path: '/shared/:interactionId',
        Component: PublicInteractionPage,
    },
    {
        path: '/shared',
        Component: PublicInteractionsPage,
    },
    // === end public
    {
        path: '*',
        Component: PageNotFound,
    }

];

function App() {

    return (
        <ToastProvider>
            <UserSessionProvider>
                <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
                    <SplashScreen />
                    <SigninScreen allowedPrefix="/shared/" />
                    <UserPermissionProvider>
                        <RouterProvider routes={routes} index="/studio" />
                    </UserPermissionProvider>
                </ThemeProvider>
            </UserSessionProvider>
        </ToastProvider>
    )
}

export default App;
