import AcesView from '@/features/settings/iam/AcesView'
import AccessControlEntryProvider from '@/features/settings/iam/AccessControlEntryProvider'
import SettingsPanel from '@/features/settings/SettingsPanel'
import { PermissionsTitle } from '@/modules/studio/features/project/RolesMappingModal'

export function ProjectRolesView() {
    return (
        <AccessControlEntryProvider>
            <div className="w-full flex flex-col gap-4">
                <SettingsPanel title={<PermissionsTitle title="User’s Project Roles" updateRole />}>
                    <AcesView type='project' />
                </SettingsPanel>
            </div>
        </AccessControlEntryProvider>
    )
}