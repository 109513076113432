import GladiaSettings from "@/features/settings/integrations/GladiaSettings";
import { useUserSession } from "@/session/UserSession";
import { useFetch } from "@reactik/hooks";
import { SupportedIntegrations } from "@vertesia/common";
import { ReactElement, ReactNode } from "react";
import AwsSettings from "./integrations/AwsSettings";


export default function IntegrationsSettings() {

    const { client, project } = useUserSession();

    const { data: integrations } = useFetch(() => project ? client.projects.integrations.list(project?.id) : Promise.resolve([]), [project?.id])


    return (
        <div className="">
            <h2 className="text-lg font-semibold">Available Integrations</h2>
            <p className="text-sm">Here you can enable and configure integration with various external services.</p>

            <div className="">
                {!integrations ?
                    <div>Loading...</div>
                    : integrations.map(integration => (
                        (INTEGRATIONS[integration.id] || undefined) &&
                        <div key={integration.id} className="px-2 py-6 rounded-lg shadow-md w-full">
                            {INTEGRATIONS[integration.id] || undefined}
                        </div>
                    ))}
            </div>

        </div>

    )

}




const INTEGRATIONS: Record<SupportedIntegrations, ReactNode> = {
    gladia: <GladiaSettings />,
    github: undefined,
    aws: <AwsSettings />,
    magic_pdf: undefined
}