import { useEffect, useState } from 'react'

import { SelectProjectSwitcher } from '@/features/project/SelectProjectSwitcher'
import { useUserSession } from '@/session/UserSession'
import { InviteUserRequestPayload, ProjectRef, ProjectRoles } from '@vertesia/common'
import { Button, Input, SelectBox, useToast } from '@reactik/components'

const ALL_PROJECTS: ProjectRef = {
    id: 'ALL_PROJECT_ID',
    name: 'All Projects',
    account: ''
}

const EMAIL_REGEX = /^([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4})(,\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4})*$/

interface InviteUserToOrgWidgetProps {
    onInvitationSend: () => void
}
export function InviteUserToOrgWidget({ onInvitationSend }: Readonly<InviteUserToOrgWidgetProps>) {
    const { account, client } = useUserSession()
    const toast = useToast()

    const [disabled, setDisabled] = useState(true)
    const [isValid, setIsValid] = useState(true)

    const [emailInput, setEmailInput] = useState<string | undefined>(undefined)
    const [inviteeRole, setInviteeRole] = useState<ProjectRoles | undefined>(undefined)

    const [projects, setProjects] = useState<ProjectRef[]>([])
    const [selectedProject, setSelectedProject] = useState<ProjectRef | undefined>(undefined)

    const inviteUser = () => {
        if (!emailInput || !inviteeRole || !selectedProject) {
            return
        }

        const trimmedInput = emailInput.replace(/\s+/g, "")
        const emailList = trimmedInput.split(",")

        const invitePromises = emailList.map(async (email) => {
            const payload: InviteUserRequestPayload = {
                email: email,
                role: inviteeRole,
                projects: selectedProject.id === ALL_PROJECTS.id ? undefined : [selectedProject.id]
            }

            const res = await client.account.inviteUser(payload)
            toast({
                status: 'success',
                title: res.action === 'added' ? 'User added to your team' : 'User invited by email',
                description: res.action === 'added' ? `User ${email} is now part of your team` : `The invite has been sent to ${email}`
            })
        })

        Promise.all(invitePromises)
            .then(() => {
                if (invitePromises.length === 1) {
                    toast({
                        status: 'info',
                        title: 'Invitation Sent',
                        description: 'The invitation has been processed successfully.'
                    })
                } else {
                    toast({
                        status: 'info',
                        title: 'Invitations Sent',
                        description: 'All invitations have been processed successfully.'
                    })
                }
            })
            .catch(() => {
                toast({
                    status: 'error',
                    title: 'Error Sending Invitations',
                    description: 'Some invitations might have failed. Please check and try again.'
                })
            })
            .finally(() => {
                onInvitationSend()
                setEmailInput(undefined)
                setInviteeRole(undefined)
                setSelectedProject(undefined)
            })
    }

    useEffect(() => {
        if (!emailInput || !inviteeRole || !selectedProject) {
            setDisabled(true)
            return
        }

        const trimmedInput = emailInput.replace(/\s+/g, "")
        const isInputValid = EMAIL_REGEX.test(trimmedInput) || trimmedInput === ""
        setIsValid(isInputValid)
        setDisabled(!isInputValid)
    }, [emailInput, inviteeRole, selectedProject])

    useEffect(() => {
        if (!client) {
            return
        }

        client.projects.list(account ? [account.id] : undefined)
            .then((projects) => {
                setProjects([ALL_PROJECTS, ...projects])
            })
    }, [client, account])

    return (
        <div>
            <div className="w-full flex flex-row gap-4">
                <Input className="w-full" onChange={setEmailInput} value={emailInput} placeholder="email address" />
                <SelectProjectSwitcher selected={selectedProject} projects={projects || []} onChange={setSelectedProject} className='min-w-[195px]' />
                <SelectBox value={inviteeRole} options={Object.values(ProjectRoles)} placeholder="Select Project Role" onChange={setInviteeRole} className='min-w-[195px]' />
                <Button onClick={inviteUser} size="md" isDisabled={disabled}>Invite User</Button>
            </div>
            {!isValid && <p style={{ color: "red" }}>Invalid email format. Use comma (,) to separate emails.</p>}
            <div className="pt-4 italic text-sm">Note: You may enter multiple email addresses, ensuring each email is separated by a comma (e.g., awesome-user1@vertesiahq.com, awesome-user2@vertesiahq.com).</div>
        </div>
    )

}
