import clsx from "clsx"
import { useState } from "react"

import { shortenString } from "@/modules/store/text"
import { useUserSession } from "@/session/UserSession"
import { AccessControlledResource, AccountRef, ProjectRef, ProjectRoles, User } from "@vertesia/common"
import { Button, SelectList, SelectListProps, useToast } from "@reactik/components"
import { useFetch, useWatchSharedState } from "@reactik/hooks"
import { useRoleSelection } from "./RoleSelectionContext"
import { RoleSelectionProvider } from "./RoleSelectionProvider"

interface SelectSectionProps<T> extends SelectListProps<T> {
    type: 'user' | 'context' | 'role'
    title: string
}
function SelectSection<T>({ title, type, onChange, ...others }: Readonly<SelectSectionProps<T>>) {
    const state = useRoleSelection()
    useWatchSharedState(state)

    const _onChange = (value: T) => {
        state.value = state.value.setValue(type, value)
        onChange && onChange(value)
    }

    const onSelect = () => {
        state.value = state.value.select(type)
    }

    const isOpen = state.value.selected === type
    const titleValue = state.value.renderValue(type)

    return (
        <div className="border border-gray-200 dark:border-slate-500 rounded-md">
            <button className={clsx('flex w-full gap-x-2 cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-slate-800', isOpen && 'bg-gray-100 dark:bg-slate-800')} onClick={onSelect}>
                <div className="font-semibold text-sm">{title}{titleValue ? ':' : ''}</div>
                <div className="text-sm text-indigo-500">{titleValue}</div>
            </button>
            {
                isOpen &&
                <div className="h-64 overflow-y-scroll">
                    <SelectList onChange={_onChange} {...others} />
                </div>
            }
        </div>
    )
}

const userLabel = (u: User) => {
    const totalLen = 25
    const rest = totalLen - u.name.length
    return {
        label: (
            < div className="flex flex-col" >
                <span className="text-sm">{u.name} ({shortenString(u.email ?? u.username, rest)})</span>
            </div >
        )
    }
}

const projectLabel = (p: ProjectRef) => {
    return {
        label: p.name
    }
}

interface SetProjectRoleComponentProps {
    onDone?: () => unknown
}
export function SetProjectRoleComponent({ onDone }: Readonly<SetProjectRoleComponentProps>) {
    const { client, account } = useUserSession()
    const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)
    const [selectedRole, setSelectedRole] = useState<ProjectRoles | undefined>(undefined)
    const [selectedProject, setSelectedProject] = useState<ProjectRef | undefined>(undefined)
    const toast = useToast()

    const type: AccessControlledResource = AccessControlledResource.project

    const { data: members } = useFetch(() => {
        return client.account.members()
    }, [account])

    const { data: projects } = useFetch(() => {
        return client.projects.list(account ? [account.id] : undefined)
    }, [account])

    const onAdd = () => {
        if (!selectedRole) {
            toast({ status: "warning", title: "Select a role", duration: 300 })
            return
        }

        if (!selectedUser) {
            toast({ status: "warning", title: "Select a user", duration: 300 })
            return
        }

        if (!selectedProject) {
            toast({ status: "warning", title: "Need an account or a project selected", duration: 300 })
            return
        }

        const resource: string = selectedProject.id

        client.iam.aces.create({
            principal: selectedUser.id,
            role: selectedRole,
            resource,
            type
        }).then(() => {
            toast({
                status: "success", title: "Role added",
                duration: 200,
                description: `Role ${selectedRole} added to ${selectedUser.name} on ${selectedProject?.name}`
            })
            onDone?.()
        })
    }

    return (
        <RoleSelectionProvider initial="context">
            <div className="flex flex-col gap-2">
                <SelectSection
                    type="context"
                    by="id"
                    title="Select a Project"
                    options={projects || []}
                    value={selectedProject}
                    optionLayout={projectLabel}
                    onChange={setSelectedProject}
                />
                <SelectSection
                    type="user"
                    title="Select a user"
                    by="id"
                    value={selectedUser}
                    options={members || []}
                    onChange={setSelectedUser}
                    optionLayout={userLabel}
                />
                <SelectSection
                    type="role"
                    title="Select a role"
                    options={Object.values(ProjectRoles)}
                    onChange={setSelectedRole}
                    value={selectedRole}
                />
                <div className="flex justify-end -mr-4 -mb-4 -mt-4 p-4 bg-transparent">
                    <Button size='lg' isDisabled={!selectedRole || !selectedProject || !selectedUser} onClick={onAdd}>Set Role</Button>
                </div>
            </div>
        </RoleSelectionProvider>
    )
}

interface ChangeOrganizationComponentProps {
    account: AccountRef
    onDone?: () => unknown
}
export function ChangeOrganizationComponent({ account, onDone }: Readonly<ChangeOrganizationComponentProps>) {
    const { client } = useUserSession()
    const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)
    const [selectedRole, setSelectedRole] = useState<ProjectRoles | undefined>(undefined)
    const toast = useToast()

    const resource: string = account.id
    const type: AccessControlledResource = AccessControlledResource.account

    const { data: members } = useFetch(() => {
        return client.account.members()
    }, [account])

    const onAdd = () => {
        if (!selectedRole) {
            toast({ status: "warning", title: "Select a role", duration: 300 })
            return
        }

        if (!selectedUser) {
            toast({ status: "warning", title: "Select a user", duration: 300 })
            return
        }

        client.iam.aces.create({
            principal: selectedUser.id,
            role: selectedRole,
            resource,
            type
        }).then(() => {
            toast({
                status: "success", title: "Role added",
                duration: 200,
                description: `Role ${selectedRole} added to ${selectedUser.name} on ${account.name}`
            })
            onDone?.()
        })
    }

    return (
        <RoleSelectionProvider initial="user">
            <div className="flex flex-col gap-2">
                <SelectSection
                    type="user"
                    title="Select a user"
                    by="id"
                    value={selectedUser}
                    options={members || []}
                    onChange={setSelectedUser}
                    optionLayout={userLabel}
                />
                <SelectSection
                    type="role"
                    title="Select a role"
                    options={Object.values(ProjectRoles)}
                    onChange={setSelectedRole}
                    value={selectedRole}
                />
                <div className="flex justify-end -mr-4 -mb-4 -mt-4 p-4 bg-transparent">
                    <Button size='lg' isDisabled={!selectedRole || !selectedUser} onClick={onAdd}>Change Role</Button>
                </div>
            </div>
        </RoleSelectionProvider>
    )
}
