import clsx from 'clsx';
import { ReactNode } from 'react';

import PanelErrorBoundary from '@/errors/PanelErrorBoundary';
import WidgetErrorBoundary from '@/errors/WidgetErrorBoundary';

interface EditorLayoutProps {
    title?: string;
    actions?: ReactNode | ReactNode[];
    children: ReactNode;
    className?: string;
}
export default function EditorLayout({ title, actions, children, className }: EditorLayoutProps) {
    return (
        <div className={clsx("flex flex-col p-2 rounded-lg shadow-sm dark:shadow-none bg-card border", className)}>
            <div className="flex items-center h-10 py-2 shrink-0">
                <div className="text-lg font-semibold">{title}</div>
                {actions && (
                    <div className="ml-auto flex gap-x-2 items-center">
                        <WidgetErrorBoundary>{actions}</WidgetErrorBoundary>
                    </div>
                )}
            </div>
            <div className="flex-1 overflow-y-auto mt-2 min-h-0">
                <PanelErrorBoundary>{children}</PanelErrorBoundary>
            </div>
        </div>
    );
}