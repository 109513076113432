import Env from "@/env";
import OIDCSignInButton from "@/features/login/OIDCSigninButton";
import { SignupData, SignupPayload } from "@vertesia/common";
import { useSafeLayoutEffect } from "@reactik/hooks";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useUserSession } from "../../session/UserSession";
import { UserNotFoundError } from "../../session/auth/composable";
import useUXTracking from "../../session/useUXTracking";
import GitHubSignInButton from "./GitHubSignInButton";
import GoogleSignInButton from "./GoogleSignInButton";
import MicrosoftSignInButton from "./MicrosoftSigninButton";
import SignupForm from "./SignupForm";
import { UIResolvedTenant } from "api/types";
import { setFirebaseTenant } from "@/session/auth/firebase";
import { isLength } from "lodash";

interface SigninScreenProps {
    isNested?: boolean;
    allowedPrefix?: string;
}
export default function SigninScreen({ allowedPrefix, isNested = false }: SigninScreenProps) {
    const [allow, setAllow] = useState(false);
    useSafeLayoutEffect(() => {
        allowedPrefix && setAllow(window.location.href.startsWith(allowedPrefix));
    }, []);
    return allow ? null : <_SigninScreen isNested={isNested} />;
}

function _SigninScreen({ isNested = false }: SigninScreenProps) {
    const { isLoading, user, authError } = useUserSession();
    const [collectSignupData, setCollectSignupData] = useState(false);

    return !isLoading && !user ? (
        <div
            style={{ zIndex: 999998 }}
            className={clsx(
                isNested ? "absolute" : "fixed",
                "inset-x-0 inset-y-0 bg-white dark:bg-slate-900 overflow-y-auto flex flex-col items-center justify-center",
            )}
        >
            {Env.firebase.tenantName ? (
                <EnterpriseSigninPanel authError={authError} />
            ) : (
                <StandardSigninPanel authError={authError} />
            )}
            <div className="flex gap-x-6 mt-12 justify-center text-slate-400 dark:text-slate-600">
                <a href="https://vertesiahq.com/privacy" className="text-sm text-slate-500">
                    Privacy Policy
                </a>
                <a href="https://vertesiahq.com/terms" className="text-sm text-slate-500">
                    Terms of Service
                </a>
            </div>
        </div>
    ) : null;
}

function EnterpriseSigninPanel({ authError }: { authError?: Error }) {
    const [tenantConfig, setTenantConfig] = useState<UIResolvedTenant | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const defaultLogo = "https://vertesiahq.com/hubfs/Vertesia-Logo/Vertesia-Logo-300x75.png";
    useEffect(() => {
        setIsLoading(true);
        if (!Env.firebase.tenantName) return;
        setFirebaseTenant().then((data) => {
            setTenantConfig(data);
            setIsLoading(false);
        });
    }, [Env.firebase.tenantName]);

    return tenantConfig && !isLoading ? (
        <div className="flex flex-col">
            <div className=" text-gray-900 dark:text-slate-200 flex-col">
                <img src={tenantConfig?.logo ?? defaultLogo} alt={tenantConfig?.name} className="h-16 mx-auto mb-8" />
                <h2 className="text-2xl font-bold text-center">Vertesia for {tenantConfig?.name}</h2>
            </div>
            <div className="flex mt-12 mb-36 items-center flex-col">
                <OIDCSignInButton icon="/Vertesia-Icon-White.svg" providerId="oidc.main" label="Connect with SSO" />
            </div>
            {authError && (
                <div className="mt-8 text-center">
                    <div className="text-slate-900 dark:text-slate-200">
                        Sorry, we have not been able to sign you in.
                        <br />
                        Please try again or contact support@vertesiahq.com if it persists.
                        <pre className="mt-2">Error: {authError.message}</pre>
                    </div>
                </div>
            )}
        </div>
    ) : (
        !isLoading && (
            <div className="mt-8 text-center">
                <div className="text-slate-900 dark:text-slate-200">Tenant not found.</div>
            </div>
        )
    );
}

function StandardSigninPanel({ authError }: { authError?: Error }) {
    const [signupData, setSignupData] = useState<SignupData | undefined>(undefined);
    const [collectSignupData, setCollectSignupData] = useState(false);
    const { signOut } = useUserSession();
    const { trackEvent } = useUXTracking();

    const goBack = () => {
        console.log("Going back, signing out");
        setSignupData(undefined);
        setCollectSignupData(false);
        signOut();
    };

    const goToSignup = () => {
        setSignupData(undefined);
        setCollectSignupData(true);
    };

    useEffect(() => {
        if (authError instanceof UserNotFoundError) {
            console.log("User not found, redirecting to signup");
            goToSignup();
        }
    }, [authError]);

    const onSignup = (data: SignupData, fbToken: string) => {
        console.log("Got Signup data", data);
        setSignupData(data);
        const payload: SignupPayload = {
            signupData: data,
            firebaseToken: fbToken,
        };
        fetch(Env.endpoints.studio + "/auth/signup", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
        }).then((res) => {
            console.log("Signup successful", payload, res);

            trackEvent("sign_up");
            window.location.href = "/";
        });
    };

    return (
        <>
            <div className=" text-gray-900 dark:text-slate-200">
                <h2 className="text-2xl font-bold text-center">Log in or Sign up</h2>
            </div>
            {signupData && (
                <div className="my-6 dark:text-slate-200">
                    Need to make a change?{" "}
                    <button
                        onClick={goToSignup}
                        className="text-indigo-500 hover:text-indigo-700 dark:hover:text-indigo-200 hover:underline hover:cursor-auto"
                    >
                        Go back
                    </button>
                </div>
            )}
            <div className="flex flex-col space-y-2">
                {collectSignupData ? (
                    <SignupForm onSignup={onSignup} goBack={goBack} />
                ) : (
                    <div className="flex flex-col">
                        <div className="flex mt-10 items-center flex-col">
                            <GoogleSignInButton />
                            <GitHubSignInButton />
                            <MicrosoftSignInButton />
                        </div>
                        {authError && (
                            <div className="mt-8 text-center">
                                <div className="text-slate-900 dark:text-slate-200">
                                    Sorry, we have not been able to sign you in.
                                    <br />
                                    Please try again or contact support@vertesiahq.com if it persists.
                                    <pre className="mt-2">Error: {authError.message}</pre>
                                </div>
                            </div>
                        )}
                        <div className="max-w-2xl text-center mt-12 text-slate-900 dark:text-slate-200">
                            First time here? No problem, it's free to try!
                            <br />
                            We'll just ask you a couple of questions next and you'll be on your way.
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
