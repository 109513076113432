import { useEffect, useState } from 'react';

import ExecutionRequestView from '@/modules/studio/features/interaction/playground/ExecutionRequestView';
import ExecutionResultView from '@/modules/studio/features/interaction/playground/ExecutionResultView';
import PlaygroundState from '@/modules/studio/features/interaction/playground/PlaygroundState';
import { useUserSession } from '@/session/UserSession';
import { useLocation } from '@reactik/router';
import { Interaction } from '@vertesia/common';

interface PlaygroundViewProps {
    interaction: Interaction;
}
export default function PlaygroundView({ interaction }: PlaygroundViewProps) {
    const { search } = useLocation();
    const { client } = useUserSession();
    const [pgState, setPgState] = useState<PlaygroundState>();

    useEffect(() => {
        const replayId = new URLSearchParams(search).get("replay_id");
        if (replayId) {
            client.runs.retrieve(replayId).then((run) => {
                if (run.interaction.id !== interaction.id) {
                    return;
                }

                setPgState(new PlaygroundState(client, {
                    ...interaction,
                    interaction_schema: interaction.result_schema,
                    result_schema: run.result_schema ?? {},
                    test_data: run.parameters,
                    environment: run.environment.id,
                    model: run.modelId,
                    model_options: run.config?.model_options,
                    restriction: run.config?.run_data,
                }));
            });
        } else {
            const interactionCopy = structuredClone(interaction);
            interactionCopy.interaction_schema = structuredClone(interaction.result_schema);
            setPgState(new PlaygroundState(client, interactionCopy));
        }
    }, [interaction, search])

    return pgState ? (
        <PlaygroundState.Provider value={pgState}>
            <div className="w-full h-full flex items-stretch gap-x-4">
                <div className="w-1/2">
                    <ExecutionRequestView />
                </div>
                <div className="w-1/2">
                    <ExecutionResultView />
                </div>
            </div>
        </PlaygroundState.Provider>
    ) : null;
}