import PanelErrorBoundary from '@/errors/PanelErrorBoundary';
import InteractionViewState from '@/modules/studio/features/interaction/InteractionViewState';
import PlaygroundState, { ConfigValidationError, PlaygroundExecutionStatus } from '@/modules/studio/features/interaction/playground/PlaygroundState';
import RunConfigurationEditor from '@/modules/studio/features/interaction/playground/RunConfigurationEditor';
import RunPayloadEditor from '@/modules/studio/features/interaction/playground/RunPayloadEditor';
import { SchemaOverrideEditor } from '@/modules/studio/features/interaction/playground/SchemaOverrideEditor';
import EditorLayout from '@/modules/studio/features/prompt/editor/EditorLayout';
import { useUserSession } from '@/session/UserSession';
import useUXTracking from '@/session/useUXTracking';
import { Play } from 'lucide-react';
import { Modalities, ModelOptions, type JSONObject } from "@llumiverse/core";
import { Button, Tab, Tabs, TabsBar, TabsPanel, useToast } from '@reactik/components';
import { ExecutionEnvironmentRef, ExecutionRun, Interaction } from '@vertesia/common';
import { useEffect, useMemo, useState } from 'react';
import { useCompositeState, useGetCompositeStateProperty, useSetCompositeStateProperty } from '@reactik/hooks';

const getTabs = (onTestDataChange: () => void, interaction?: Interaction): Tab[] => {
    return [
        {
            name: "parameters",
            label: "Parameters",
            content: <RunPayloadEditor onChange={onTestDataChange} />
        },
        {
            name: "configuration",
            label: "Configuration",
            content: <RunConfigurationEditor />
        },
        {
            name: "result_schema",
            label: "Result Schema",
            content: <SchemaOverrideEditor />
        }
    ];
}

export default function ExecutionRequestView() {
    const { client } = useUserSession();
    const playgroundState = useCompositeState(PlaygroundState);
    const interaction = playgroundState.interaction;
    const [env, setEnv] = useState<ExecutionEnvironmentRef | undefined>(playgroundState.env || undefined);

    useEffect(() => {
        playgroundState.config = {
            model_options: { ...interaction.model_options, ...playgroundState.config?.model_options } as ModelOptions,
            run_data: playgroundState.config?.run_data || interaction.restriction,
            model: playgroundState.config?.model || interaction.model,
            environment: env?.id
        };
        playgroundState.env = env;
        playgroundState.result_schema = playgroundState.result_schema || interaction.result_schema;
    }, [env, playgroundState]);

    useEffect(() => {
        if (!playgroundState.env && interaction.environment) {
            client.environments
                .retrieve(interaction.environment as string)
                .then((env) => {
                    setEnv(env);
                })
                .catch((err) => {
                    console.error("Failed to load execution environment", err);
                });
        }
    }, [interaction.environment, client.environments]);

    const sharedState = useCompositeState(InteractionViewState);

    const onTestDataChange = () => {
        if (sharedState) {
            sharedState.isDirty.value = true;
        }
    };

    return (
        <EditorLayout title="Execution Payload" actions={<RunButton />} className='h-[calc(100vh-189px)]'>
            <Tabs tabs={getTabs(onTestDataChange)}>
                <TabsBar />
                <PanelErrorBoundary>
                    <TabsPanel />
                </PanelErrorBoundary>
            </Tabs>
            {sharedState && <SaveProvider sharedState={sharedState} />}
        </EditorLayout>
    );
}

interface SaveProviderProps {
    sharedState: InteractionViewState;
}
function SaveProvider({ sharedState }: SaveProviderProps) {
    const playgroundState = useCompositeState(PlaygroundState);
    const { client } = useUserSession();

    const onSaveTestData = useMemo(() => () => {
        return client.interactions.update(playgroundState.interaction.id, {
            test_data: playgroundState.payload.value as JSONObject,
        });
    }, [playgroundState.interaction]);

    useSetCompositeStateProperty(sharedState.save, onSaveTestData);

    return null;
}

function RunButton() {
    const toast = useToast();
    const [isRunning, setIsRunning] = useState(false);
    const playgroundState = useCompositeState(PlaygroundState);
    const { trackEvent } = useUXTracking();
    const status = useGetCompositeStateProperty(playgroundState.executionStatus);
    const isStreaming = status === PlaygroundExecutionStatus.streaming;

    const run = () => {
        setIsRunning(true);
        playgroundState
            .run()
            .then((run: ExecutionRun) => {
                trackEvent("run_interaction", {
                    zone: 'playground',
                    model: run.modelId,
                    status: run.status
                });
            }).catch((err: any) => {
                let title, description;
                if (err instanceof ConfigValidationError) {
                    title = "Invalid configuration";
                    description = err.message;
                } else {
                    title = "Failed to run interaction";
                    description = err.message;
                }
                toast({
                    status: "error",
                    title,
                    description,
                    duration: 6000,
                });
            })
            .finally(() => {
                setIsRunning(false);
            });
    };


    return (
        <Button isLoading={isRunning} onClick={run} isDisabled={isStreaming}>
            <Play className="size-5" fill='currentColor' />
            Run
        </Button>
    );
}