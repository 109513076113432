import { CircleX, CircleCheck, AlertTriangle, Info } from 'lucide-react';
import React from 'react';

interface MessageBoxProps {
    status: 'error' | 'info' | 'warning' | 'success'
    title: string
    children: React.ReactNode | React.ReactNode[]
    className?: string
}
export function MessageBox({ status, title, children, className }: MessageBoxProps) {

    let icon, titleColor, textColor, bgColor;
    switch (status) {
        case 'error': {
            icon = <CircleX className="size-5 text-danger-muted" aria-hidden="true" />
            titleColor = "";
            textColor = "text-muted-foreground";
            bgColor = "bg-danger dark:bg-danger/10 border border-danger-muted dark:border-danger-muted/50 p-4";
            break;
        }
        case 'warning': {
            icon = <AlertTriangle className="size-5 text-attention-muted" aria-hidden="true" />
            titleColor = "";
            textColor = "text-muted-foreground";
            bgColor = "bg-attention dark:bg-attention/10 border border-attention-muted dark:border-attention-muted/50 p-4";
            break;
        }
        case 'success': {
            icon = <CircleCheck className="size-5 text-success-muted" aria-hidden="true" />
            titleColor = "";
            textColor = "text-muted-foreground";
            bgColor = "bg-success dark:bg-success/10 border border-success-muted dark:border-success-muted/50 p-4";
            break;
        }
        case 'info': {
            icon = <Info className="size-5 text-info-muted" aria-hidden="true" />
            titleColor = "";
            textColor = "text-muted-foreground";
            bgColor = "bg-info dark:bg-info/10 border border-info-muted dark:border-info-muted/50 p-4";
            break;
        }
    }

    return (
        <div className={`rounded-md p-4 ${bgColor} ${className}`}>
            <div className="flex">
                <div className="shrink-0">
                    {icon}
                </div>
                <div className="ml-3">
                    <h3 className={`text-sm font-medium ${titleColor}`}>{title}</h3>
                    <div className={`mt-2 text-sm ${textColor}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div >
    )
}

export function ErrorBox({ title, className, children }: { title: string, className?:string, children: React.ReactNode }) {
    return <MessageBox status="error" title={title} className={className}><pre>{children}</pre></MessageBox>
}

export function InfoBox({ title, className, children }: { title: string, className?:string, children: React.ReactNode }) {
    return <MessageBox status="info" title={title} className={className}>{children}</MessageBox>
}

export function WarningBox({ title, className, children }: { title: string, className?:string, children: React.ReactNode }) {
    return <MessageBox status="warning" title={title} className={className}>{children}</MessageBox>
}

export function SuccessBox({ title, className, children }: { title: string, className?:string, children: React.ReactNode }) {
    return <MessageBox status="success" title={title} className={className}>{children}</MessageBox>
}
