import { ReactNode } from 'react';

import Popover from '@/components/popover/Popover';

interface TooltipPopoverProps {
    description?: ReactNode;
    children: ReactNode;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    placement?: 'top' | 'right' | 'bottom' | 'left';
}
export function TooltipPopover({ description, children, size = 'md', placement = 'top'}: TooltipPopoverProps) {
    return (
        <Popover strategy='fixed' placement={placement} zIndex={100}>
            <Popover.Trigger hover className="cursor-pointer px-2">
                {children}
            </Popover.Trigger>
            <Popover.Content>
                <div className={`bg-popover text-popover-foreground rounded-md shadow-md py-2 max-w-${size} text-${size}  px-3`}>
                    {description}
                </div>
            </Popover.Content>
        </Popover>
    );
};
