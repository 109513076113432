import { useState } from 'react';

import { useUserSession } from '@/session/UserSession';
import { ApiKey } from '@vertesia/common';
import { Trash2 } from 'lucide-react';
import { Button, Modal, ModalBody, ModalFooter, ModalTitle, useToast } from '@reactik/components';

interface APIKeyButtonProps {
    apiKey: ApiKey;
    setRefetch: (r: boolean) => void;
    setError: (value: string) => void;
}
export default function APIKeyButton({ apiKey, setRefetch, setError }: Readonly<APIKeyButtonProps>) {
    const [showDelete, setShowDelete] = useState(false);
    return (
        <>
            <APIKeyDeleteModal isOpen={showDelete} apiKey={apiKey} onClose={() => setShowDelete(false)} setRefetch={setRefetch} setError={setError} />
            <Button variant="ghost" onClick={() => setShowDelete(true)}>
                <Trash2 className="size-5 text-red-500" />
            </Button>
        </>
    );
}

interface APIKeyDeleteModalProps {
    isOpen: boolean;
    apiKey: ApiKey;
    onClose: () => void;
    setRefetch: (r: boolean) => void;
    setError: (value: string) => void;
}
function APIKeyDeleteModal({ isOpen, apiKey, onClose, setRefetch, setError }: Readonly<APIKeyDeleteModalProps>) {
    const { client } = useUserSession();
    const toast = useToast();

    const deleteKey = () => {
        apiKey.id && client.apikeys.delete(apiKey.id).then(() => {
            toast({
                title: `${apiKey.name} has been deleted`,
                status: "success",
                duration: 3000
            });
            setRefetch(true);
        }).catch((err) => {
            setError(err.message)
        })
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalTitle>Delete API Key</ModalTitle>
            <ModalBody>
                <p className="text-slate-800 dark:text-slate-50">
                    Are you sure you want to delete this API Key? {' '}
                    <span className='font-bold italic'>{apiKey.name}</span>
                </p>
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <Button variant="primary" onClick={deleteKey}>Delete</Button>
            </ModalFooter>
        </Modal>
    );
}
