import clsx from "clsx"

import { SelectBox } from "@reactik/components"
import { ProjectRef } from "@vertesia/common"

interface SelectProjectSwitcherProps {
    className?: string
    onChange: (project: ProjectRef) => void
    selected?: ProjectRef
    projects: ProjectRef[]
    placeholder?: string
}
export function SelectProjectSwitcher({ className, projects, selected, placeholder, onChange }: Readonly<SelectProjectSwitcherProps>) {
    const getProjectLabel = (project: ProjectRef) => {
        return project.name
    }

    return (
        <SelectBox
            by="name"
            className={clsx('w-auto', className)}
            value={selected}
            options={projects}
            optionLabel={(option) => getProjectLabel(option)}
            placeholder={placeholder ?? 'Select Project'}
            filterBy="name"
            onChange={onChange}
        />
    )
}