import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { Trash2 } from 'lucide-react'
import { useEffect, useState } from 'react'

import { ResourceInfo } from '@/features/settings/iam/components/ResourceInfo'
import ConfirmActionButton from '@/modules/store/features/store/objects/ConfirmActionButton'
import { Table, TBody, useToast } from '@reactik/components'
import { useUserSession } from '@/session/UserSession'
import { TransientToken, UserInviteTokenData } from "@vertesia/common"

dayjs.extend(LocalizedFormat)

interface ViewPendingInvitationsProps {
    refresh: boolean
}
export function ViewPendingInvitations({ refresh }: Readonly<ViewPendingInvitationsProps>) {
    const { account, client } = useUserSession()
    const toast = useToast()

    const [invitations, setInvitations] = useState<TransientToken<UserInviteTokenData>[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const searchInvitations = (accountId: string) => {
        setIsLoading(true)

        client.account.listAccountInvitation(accountId)
            .then((invitations) => {
                setInvitations(invitations)
            }).catch((err) => {
                toast({
                    status: "error",
                    title: "Error while retrieving invitations",
                    description: err.message
                })
            }).finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (!client || !account) {
            return
        }

        searchInvitations(account.id)
    }, [account, client, refresh])

    const OnDeleteInvitation = (id: string) => {
        client.account.rejectInvite(id).then(() => {
            toast({
                status: "success",
                title: `Invitation ${id} deleted`
            })

            if (account) {
                searchInvitations(account.id)
            }
        }).catch((err) => {
            toast({
                status: "error",
                title: `Cannot delete invitation ${id}`,
                description: err.message
            })
        })
    }

    return (
        <div>
            <Table className="w-full">
                <thead>
                    <tr>
                        <td>Email</td>
                        <td>Role</td>
                        <td>Invited By</td>
                        <td>Expires At</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <TBody columns={5} isLoading={isLoading}>
                    {
                        invitations?.map((invitation) => {
                            return (
                                <tr key={invitation.id}>
                                    <td>{invitation.data.email}</td>
                                    <td>{invitation.data.role}</td>
                                    <td><ResourceInfo resource={invitation.data.invitedBy} /></td>
                                    <td>{dayjs(invitation.expires).format('LL')}</td>
                                    <td>
                                        <ConfirmActionButton
                                            variant="ghost"
                                            doAction={() => OnDeleteInvitation(invitation.id)}
                                            confirmationText={`Are you sure you want to delete this invitation?`}
                                        >
                                            <Trash2 className="size-5 text-red-500" />
                                        </ConfirmActionButton>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </TBody>
            </Table>
        </div>
    )

}
