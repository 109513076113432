import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { firebaseAuth } from "../../session/auth/firebase";

interface GoogleSignInButtonProps {
    redirectTo?: string;
}
export default function GoogleSignInButton({ redirectTo }: GoogleSignInButtonProps) {

    const signIn = () => {
        let redirectPath = redirectTo || window.location.pathname || '/';
        if (redirectPath[0] !== '/') {
            redirectPath = '/' + redirectPath;
        }
        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        // always ask to select the google account
        //console.log('redirectPath', window.location.origin + redirectPath)
        provider.setCustomParameters({
            prompt: 'select_account',
            redirect_uri: window.location.origin + redirectPath
        });
        signInWithRedirect(firebaseAuth, provider);
    };

    return (
        <button
            onClick={signIn}
            className="px-4 py-2 w-52 border flex gap-2 border-slate-200 rounded-lg text-slate-700 dark:text-slate-300 hover:border-slate-400 hover:text-slate-900 dark:hover:text-slate-200  hover:shadow-sm transition duration-150">
            <img className="size-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
            <span className="text-sm font-semibold">Login with Google</span>
        </button>
    );
}
