import { ReactNode, useMemo } from "react"
import { RoleSelection, RoleSelectionContext } from "./RoleSelectionContext"
import { SharedState } from "@reactik/hooks"

interface RoleSelectionProviderProps {
    initial?: 'user' | 'context' | 'role'
    children: ReactNode
}
export function RoleSelectionProvider({ initial, children }: Readonly<RoleSelectionProviderProps>) {
    const context = useMemo(() => new SharedState(new RoleSelection()), [])
    context.value.selected = initial

    return (
        <RoleSelectionContext.Provider value={context}>
            {children}
        </RoleSelectionContext.Provider>
    )
}
