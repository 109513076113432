import clsx from "clsx"
import UserNavAvatar from './features/login/UserNavAvatar';
import { useUserSession } from "./session/UserSession";
import Env from './env';
import { cn } from "./utils/cn";

interface NavBarProps {
    className?: string
    children?: React.ReactNode | React.ReactNode[]
}

export function NavBar({ className }: NavBarProps) {
    const session = useUserSession();
    const { user, } = session;

    if (!session || !user) return null;
    // const envTag = Env.isDev ? 'staging' : Env.isPreview ? 'preview' : '';
    const topnavBg = 'bg-sidebar text-sidebar-foreground border-sidebar-border';

    return (
        <div className={
            clsx(
                className, topnavBg,
                'h-10 flex justify-between',
                'border-b',
            )}
        >
            <ul className="flex items-center justify-start">
                <TopBarItem href='/studio/dashboard'>
                    <img src={'/logo-light.png'} alt='logo' className='h-6 block dark:hidden' />
                    <img src={'/logo-dark.png'} alt='logo' className='h-6 hidden dark:block' />
                </TopBarItem>
                <EnvTag env={Env} />
            </ul>
            <ul className="flex items-center justify-end mx-3">
                <TopBarItem href='https://help.vertesiahq.com/' target="_blank">Help</TopBarItem>
                <TopBarItem href='https://docs.vertesiahq.com/' target="_blank">Docs</TopBarItem>
                <UserNavAvatar />
            </ul>
        </div>
    )
}

export interface TopBarItemProps {
    href?: string
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
    children: React.ReactNode | React.ReactNode[]
    className?: string;
    id?: string;
    target?: string;
}
export function TopBarItem({ className, children, href, target, onClick }: TopBarItemProps) {
    return (
        <li>
            <a
                target={target ? target : '_self'}
                href={href}
                onClick={onClick}
                className={clsx(
                    className,
                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                )}
            >
                {children}
            </a>
        </li>

    )
}

export function EnvTag({ env }: { env: typeof Env }) {
    const envTag = env.type;

    const envClassMap: Record<string, string> = {
        development: 'bg-development',
        preview: 'bg-preview',
        staging: 'bg-staging',
        production: 'bg-production',
    };

    return (
        !Env.isProd && (
            <li>
                <div
                    className={cn(
                        `text-[0.6rem] font-semibold leading-6px-1 rounded-3xl px-1.5 text-white`,
                        envClassMap[envTag] || 'bg-development' // Fallback to development
                    )}
                    style={{ lineHeight: '1rem' }}
                >
                    {envTag}
                </div>
            </li>
        )
    );
}