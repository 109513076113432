import { OAuthProvider, signInWithRedirect } from "firebase/auth";
import { firebaseAuth, setFirebaseTenant } from "../../session/auth/firebase";

interface OIDCSignInButtonProps {
    redirectTo?: string;
    providerId: string;
    label: string;
    icon?: string;
}
export default function OIDCSignInButton({ providerId, label, icon }: OIDCSignInButtonProps) {
    const signIn = async () => {
        const provider = new OAuthProvider(providerId);
        signInWithRedirect(firebaseAuth, provider);
    };

    return (
        <button
            onClick={signIn}
            className="my-2 flex w-52 gap-2 rounded-lg border border-slate-200 px-4 py-2 text-slate-700 transition duration-150  dark:text-slate-300 hover:border-slate-400 hover:text-slate-900 dark:hover:text-slate-200 hover:shadow-sm items-center justify-center"
        >
            {icon && <img className="size-6" src={icon} loading="lazy" />}
            <span className="text-sm font-semibold">{label}</span>
        </button>
    );
}
