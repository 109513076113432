import { useUserSession } from "@/session/UserSession";
import { AccessControlledResource, ProjectRef, ResolvableRef, ResolvableRefType } from "@vertesia/common";
import { useFetch } from "@reactik/hooks";
import { useMemo } from "react";
import { AccessControlEntryRegistry, LoadingAccessControlEntryRegistry, PermissionsProviderReactContext } from "./AccessControlEntryRegistry";

export function convertToResolvableRefType(type: AccessControlledResource) {
    switch (type) {
        case AccessControlledResource.account: return ResolvableRefType.account
        case AccessControlledResource.environment: return ResolvableRefType.environment
        case AccessControlledResource.interaction: return ResolvableRefType.interaction
        case AccessControlledResource.project: return ResolvableRefType.project
    }
}

interface AccessControlEntryProviderProps {
    project?: ProjectRef,
    children: React.ReactNode
}
export default function AccessControlEntryProvider({ project, children }: Readonly<AccessControlEntryProviderProps>) {
    const { client } = useUserSession();
    const { data: aces, isLoading, refetch } = useFetch(() => {
        return client.iam.aces.list({ level: project ? 'project' : undefined });
    }, [project]);


    const { data: refs, isLoading: refLoading, refetch: refRefetch } = useFetch(() => {
        if (!aces?.length) {
            return Promise.resolve([]);
        }
        const refs: ResolvableRef[] = []
        for (const ace of aces) {
            if (!refs.find(r => r.id === ace.resource)) {
                refs.push({
                    id: ace.resource,
                    type: convertToResolvableRefType(ace.type)
                })
            }

            if (!refs.find(r => r.id === ace.principal)) {
                refs.push({
                    id: ace.principal,
                    type: ResolvableRefType.user
                })
            }
        }

        return client.refs.resolve(refs)
    }, [aces])

    const registry = useMemo(() => {
        const refresh = () => Promise.all([refetch(), refRefetch()]);
        if (aces && refs && !isLoading && !refLoading) {
            return new AccessControlEntryRegistry(aces, refs, refresh);
        }
        return LoadingAccessControlEntryRegistry;
    }, [aces, refs, isLoading, refLoading]);

    return (
        <PermissionsProviderReactContext.Provider value={registry}>
            {children}
        </PermissionsProviderReactContext.Provider>
    )
}