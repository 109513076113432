import clsx from 'clsx';
import React, { ChangeEvent } from 'react';

import { X } from 'lucide-react';

import { Styles } from './styles.js';

interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'value'> {
    value?: string;
    onChange?: (value: string) => void
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(({ value, onChange, className, type, ...others }: InputProps, ref) => {
    const _onChange = (ev: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(ev.target.value);
    };

    const _onClear = () => {
        onChange && onChange('');
    };

    return (
        <div className="w-full" style={{ position: 'relative', display: 'inline-block' }}>
            <input
                type={type || 'text'}
                value={value == null ? '' : value}
                onChange={_onChange}
                className={clsx('w-full', Styles.INPUT, className)}
                ref={ref}
                {...others}
            />
            {
                value && (
                    <button onClick={_onClear} className={`absolute ${type !== 'number' ? 'right-2' : 'right-7'} top-1/2 -translate-y-1/2 size-7 text-gray-400 hover:text-red-500 cursor-pointer`}>
                        <X />
                    </button>
                )
            }
        </div>
    );
})

export { Input };
