import { useState } from 'react';

import SelectObjectTypeModal from '@/modules/store/features/store/objects/SelectObjectTypeModal';
import { useUserSession } from '@/session/UserSession';
import { ImageGeneration } from '@llumiverse/core';
import { Button, useToast } from '@reactik/components';

// Subcomponent for individual image display
const ImageCard = ({ src, interaction_name }: { src: string, interaction_name?: string }) => {
  const [error, setError] = useState(false);
  const isBase64 = !src.startsWith('http');

  const { client } = useUserSession();
  const toast = useToast();
  const [open, setOpen] = useState(false);
  const [isUploading, setUploading] = useState(false);

  const handleOnOpen = () => {
    setOpen(true);
  };

  const handleOnClose = async (typeId?: string | null | undefined) => {
    setOpen(false);

    if (typeId === undefined) {
      return;
    }

    setUploading(true);
    const numString = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
    const filename = `generated_${interaction_name || 'image'}_${numString}.jpeg`;
    const file = base64ToFile(src, filename);

    await client.objects.create({
      type: typeId || undefined,
      content: file
    }).then((res) => {
      toast({
        title: 'Document Upload Success',
        status: 'success',
        description: `Document ${res.id} successfully uploaded`,
        duration: 3000
      });
    }).catch((error: any) => {
      toast({
        title: 'Document Upload Error',
        status: 'error',
        description: `Failed to upload document: ${error.message}`,
        duration: 3000
      });
    });
    setUploading(false);
  };

  const handleError = () => {
    setError(true);
  };

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-64 bg-gray-100 rounded-lg">
        <p className="text-gray-500">Failed to load image</p>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-end">
        <Button onClick={handleOnOpen} isDisabled={isUploading}>Save</Button>
        <SelectObjectTypeModal onClose={handleOnClose} isOpen={open} actionLabel="Upload" title="Select Content Type">
          <div className="text-sm">
            Select the associated Content Type, or let the system choose or generate the type based on the content.
          </div>
        </SelectObjectTypeModal>
      </div>
      <div className="relative group overflow-hidden rounded-lg bg-gray-50">
        <img
          src={isBase64 ? `data:image/jpeg;base64, ${src}` : src}
          alt={`Generated ${isBase64 ? 'base64' : 'URL'} image`}
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
          loading="lazy"
          onError={handleError}
        />

        {/* Optional zoom overlay on hover */}
        <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      </div>
    </>
  );
};

// Subcomponent for the image grid
const ImageGrid = ({ images, interaction_name }: { images: string[], interaction_name?: string }) => {
  const useDoubleColumn = images.length > 1;

  return (
    <div className={`grid gap-4 ${useDoubleColumn ? 'grid-cols-1 md:grid-cols-2' : 'grid-cols-1'}`}>
      {images.map((image, index) => (
        <ImageCard key={index} src={image} interaction_name={interaction_name} />
      ))}
    </div>
  );
};

// Main component
interface ImageOutputDisplayProps {
  result: ImageGeneration;
  interaction_name?: string;
}

const ImageOutputDisplay = ({ result, interaction_name}: ImageOutputDisplayProps) => {
  const { images } = result;

  if (!images || images?.length === 0) {
    return (
      <div className="p-4 text-gray-500 text-center rounded-lg border border-gray-200">
        No images to display
      </div>
    );
  }

  return (
    <div className="w-full mt-4">
      <ImageGrid images={images} interaction_name={interaction_name} />
    </div>
  );
};

function base64ToFile(content: string, filename: string) {
  const byteCharacters = atob(content);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/jpeg' });

  return new File([blob], filename, { type: 'image/jpeg' });
}

export default ImageOutputDisplay;