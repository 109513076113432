import { Files } from 'lucide-react';
import { AIModel } from '@llumiverse/core';
import { Button, useToast } from '@reactik/components';

interface ModelIdBoxProps {
    model: AIModel;
}
export default function ModelIdBox({ model }: ModelIdBoxProps) {
    const toast = useToast();

    const onCopy = () => {
        navigator.clipboard.writeText(model.id);
        toast({
            status: 'info',
            title: 'ID copied to clipboard',
            duration: 1000
        })
    }

    return (
        <div className="w-full group text-sm flex gap-2 items-center">
                <div className="shrink-0">ID:</div>
                <div className="truncate">{model.id}</div>
                <Button variant="soft" className="text-xs hidden group-hover:block" onClick={onCopy} size="xs">
                    <Files className="size-3" />
                </Button>
        </div>
    );
}
